import { useStoreContext } from 'hooks/useStoreContext'
import { useCallback } from 'react'
import { FiltersGroupConfig } from 'types/filter'

enum FilterType {
  Radio = 'radio',
  Checkbox = 'checkbox',
  Color = 'color',
  Range = 'range',
  Brand = 'brand',
  Flag = 'flag',
  Price = 'price',
}

export const FILTER_TYPE_CHECKBOX = FilterType.Checkbox
export const FILTER_TYPE_BRAND = FilterType.Brand
export const FILTER_TYPE_FLAG = FilterType.Flag
export const FILTER_TYPE_RADIO = FilterType.Radio

/**
 * Default filters state. Every filter has following fields:
 * 1) key - key of corresponding category received from API ('kind' field)
 * 2) label - translation key to display in title
 * 3) type - type of filter (checkbox, color, range)
 * 4) options - array of available options.
 *
 * Each filter option has following fields:
 * 1) active - if option is selected
 * 2) count - number of products corresponding the option (received from API)
 * 3) label - translation label
 * 4) value - value of the option received from API ('value' field)
 */

export const DEFAULT_FILTERS = {
  brand: {
    key: 'brand',
    label: 'filter-by-brand',
    type: FILTER_TYPE_BRAND,
  },
  category: {
    key: 'category',
    label: 'category',
    type: FILTER_TYPE_CHECKBOX,
  },
  productCategory: {
    key: 'productCategory',
    label: 'productCategory',
    type: FILTER_TYPE_CHECKBOX,
  },
  gender: {
    key: 'gender',
    label: 'gender',
    type: FILTER_TYPE_RADIO,
  },
  childAgeRange: {
    key: 'childAgeRange',
    label: 'childAgeRange',
    type: FILTER_TYPE_FLAG,
  },
  geoFit: {
    key: 'geoFit',
    label: 'bridgeNosepads',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameColor: {
    key: 'frameColor',
    label: 'frameColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameMaterialFacet: {
    key: 'frameMaterialFacet',
    label: 'frameMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameShapeFacet: {
    key: 'frameShapeFacet',
    label: 'frameShape',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameSizeFamily: {
    key: 'frameSizeFamily',
    label: 'size',
    type: FILTER_TYPE_CHECKBOX,
  },
  frameFitting: {
    key: 'frameFitting',
    label: 'geoFit',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensMaterial: {
    key: 'lensMaterial',
    label: 'lensMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensColorfacet: {
    key: 'lensColorfacet',
    label: 'lensColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensProperties: {
    key: 'lensProperties',
    label: 'lensProperties',
    type: FILTER_TYPE_CHECKBOX,
  },
  templeMaterial: {
    key: 'templeMaterial',
    label: 'templeMaterial',
    type: FILTER_TYPE_CHECKBOX,
  },
  templeColorFacet: {
    key: 'templeColorFacet',
    label: 'templeColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  frontColorFacet: {
    key: 'frontColorFacet',
    label: 'frontColor',
    type: FILTER_TYPE_CHECKBOX,
  },
  specialFeatures: {
    key: 'specialFeatures',
    label: 'specialFeatures',
    type: FILTER_TYPE_CHECKBOX,
  },
  featuresAndUsage: {
    key: 'featuresAndUsage',
    label: 'featuresAndUsage',
    type: FILTER_TYPE_CHECKBOX,
  },
  weatherCondition: {
    key: 'weatherCondition',
    label: 'weatherCondition',
    type: FILTER_TYPE_CHECKBOX,
  },
  lensHeightString: {
    key: 'lensHeightString',
    label: 'lensHeight',
    type: FILTER_TYPE_CHECKBOX,
  },
  vmEnabled: {
    key: 'vmEnabled',
    label: 'vmEnabled',
    type: FILTER_TYPE_FLAG,
  },
  rtr: {
    key: 'rtr',
    label: 'rtr',
    type: FILTER_TYPE_FLAG,
  },
  nxStarsFilter: {
    key: 'nxStarsFilter',
    label: 'nxStarsFilter',
    type: FILTER_TYPE_FLAG,
  },
  collection: {
    key: 'collection',
    label: 'collection',
    type: FILTER_TYPE_RADIO,
  },
  bestNew: {
    key: 'bestNew',
    label: 'bestNew',
    type: FILTER_TYPE_RADIO,
  },
  bestSeller: {
    key: 'bestSeller',
    label: 'bestNew',
    type: FILTER_TYPE_RADIO,
  },
  new: {
    key: 'new',
    label: 'bestNew',
    type: FILTER_TYPE_RADIO,
  },
  polarized: {
    key: 'polarized',
    label: 'polarized',
    type: FILTER_TYPE_FLAG,
  },
  contrastEnhancement: {
    key: 'contrastEnhancement',
    label: 'contrastEnhancement',
    type: FILTER_TYPE_FLAG,
  },
  photochromic: {
    key: 'photochromic',
    label: 'photochromic',
    type: FILTER_TYPE_FLAG,
  },
  theme: {
    key: 'theme',
    label: 'theme',
    type: FILTER_TYPE_RADIO,
  },
  firstLevel: {
    key: 'firstLevel',
    label: 'firstLevel',
    type: FILTER_TYPE_CHECKBOX,
  },
  secondLevel: {
    key: 'secondLevel',
    label: 'secondLevel',
    type: FILTER_TYPE_CHECKBOX,
  },
}

export interface Category {
  options?: { value: string; label: string; name?: string }[]
  label?: string
  value?: string
  name?: string
}
/*
  key of the option is a name of a filter from products filters
  name and value are the same as used in
  url filter: name=values
*/
export const CATEGORIES: Record<string, Category> = {
  polarized: {
    name: 'polarized',
    label: 'Pages.polarized',
    value: 'true',
  },
  bestSeller: {
    name: 'bestNew',
    label: 'Pages.bestSellers',
    value: 'bestSeller',
  },
  new: {
    name: 'bestNew',
    label: 'Pages.newArrivals',
    value: 'new',
  },
  theme: {
    options: [
      {
        name: 'theme',
        value: 'SPORT PERFORMANCE',
        label: 'Pages.sportPerformance',
      },
      {
        name: 'theme',
        value: 'PERFORMANCE LIFESTYLE',
        label: 'Pages.performanceLifestyle',
      },
    ],
  },
}

export type FilterKind = keyof typeof DEFAULT_FILTERS

export type DefaultFilter = {
  key: FilterKind
  label: string
  type: FilterType
}

export type DefaultFilters = {
  [kind in FilterKind]: DefaultFilter
}

const basicOtherCategoryFilters = ['polarized', 'contrastEnhancement', 'photochromic', 'rtr']

const useGetOtherCategoryFilters = () => {
  const store = useStoreContext()

  const getOtherCategoryFilters = useCallback(
    (othersList: string[] = []) => {
      const baseOthers = [...basicOtherCategoryFilters, ...othersList]
      return store.stars ? [...baseOthers, 'nxStarsFilter'] : baseOthers
    },
    [store.stars]
  )
  return { getOtherCategoryFilters }
}

export const useGetWhitelistedFilters = () => {
  const { getOtherCategoryFilters } = useGetOtherCategoryFilters()

  return {
    eyewearAccessories: {
      filters: [
        'productCategory',
        'brand',
        'lensColorfacet',
        'lensProperties',
        'lensMaterial',
        'frameSizeFamily',
        'frameColor',
        'frameMaterialFacet',
        'frameFitting',
        'frontColorFacet',
        'other',
      ],
      other: getOtherCategoryFilters(),
    },
    replacementLenses: {
      filters: [
        'productCategory',
        'brand',
        'lensColorfacet',
        'lensProperties',
        'lensMaterial',
        'other',
      ],
      other: getOtherCategoryFilters(),
    },
    frameComponents: {
      filters: ['productCategory', 'brand', 'frontColorFacet', 'frameMaterialFacet'],
      other: [],
    },
    otherAccessories: {
      filters: ['productCategory', 'brand', 'frontColorFacet'],
      other: [],
    },
    goggles: {
      filters: [
        'gender',
        'childAgeRange',
        'frameSizeFamily',
        'frameFitting',
        'geoFit',
        'brand',
        'lensColorfacet',
        'frameMaterialFacet',
        'lensProperties',
        'frontColorFacet',
        'weatherCondition',
        'other',
      ],
      other: getOtherCategoryFilters(),
    },
    eyeglasses: {
      filters: [
        'categories',
        'brand',
        'gender',
        'childAgeRange',
        'frameMaterialFacet',
        'frameColor',
        'frameShapeFacet',
        'frameSizeFamily',
        'frameFitting',
        'geoFit',
        'lensMaterial',
        'lensColorfacet',
        'lensProperties',
        'templeMaterial',
        'templeColorFacet',
        'specialFeatures',
        'featuresAndUsage',
        'lensHeightString',
        'other',
      ],
      other: getOtherCategoryFilters(['vmEnabled']),
    },
  }
}

export const getDefaultFilterGroupConfig = (): FiltersGroupConfig => {
  const filterKindList = Object.keys(DEFAULT_FILTERS) as FilterKind[]
  let config: FiltersGroupConfig = {}
  filterKindList.map(kind => {
    config[kind] = null
  })
  return config
}
