import { FrameAdvisorCapture } from '@luxottica/capture-component'
import { clearFsa, setOnCaptureProcess } from 'actions/frameAdvisor'
import { debounce } from 'lodash'
import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { PageChecker } from 'types/common'
import { useActions } from './useActions'
import { useStoreIndentity } from './useStoreIdentity'

export const useMediaQuery = (query: string) => {
  const [queryMatch, setQueryMatch] = useState<boolean | null>(null)

  useEffect(() => {
    const handleQueryListener = debounce(() => {
      const updatedMatches = window.matchMedia(query)
      setQueryMatch(updatedMatches.matches)
    }, 250)

    handleQueryListener()
    window.addEventListener('resize', handleQueryListener)

    return () => {
      window.removeEventListener('resize', handleQueryListener)
    }
  }, [query])

  return queryMatch
}

// !IMPORTANT:
// !In order for this to work, the window needs to be used without console open(or anything that can modify it's internal window. For example, on a page width 1920, if the console is open, the external window results in 1920 but the internal results smaller (depending on the width of the dev console). This will cause an issue where, external/internal will not be 1 and therefore not be seen as 100% zoom level. This method as been tested on chromium as well and works fine if we remember to use the console detached from the window and the window the exact size needed (1080x1920)
export const useZoomLevel = () => {
  const [zoomLevel, setZoomLevel] = useState<number>(100)

  useEffect(() => {
    const handleZoomListener = debounce(() => {
      const zoom = Math.round((window.outerWidth / window.innerWidth) * 100) || 100
      setZoomLevel(zoom)
    }, 250)

    handleZoomListener()
    window.addEventListener('resize', handleZoomListener)

    return () => {
      window.removeEventListener('resize', handleZoomListener)
    }
  }, [])

  return zoomLevel
}

export const usePageChecker = () => {
  const { basePath } = useStoreIndentity()
  const isGensPage = !!useRouteMatch({ path: `${basePath}/gen-s`, exact: false })
  const isBrandsPage = !!useRouteMatch({ path: `${basePath}/brands`, exact: true })
  const isCartPage = !!useRouteMatch({ path: `${basePath}/cart`, exact: true })
  const isCheckoutV3Page = !!useRouteMatch({ path: `${basePath}/checkout-v3`, exact: true })
  const isCustomPage = !!useRouteMatch({ path: `${basePath}/custom`, exact: false })
  const isFrameAdvisorPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor`,
    exact: true,
  })
  const isFrameAdvisorOnboardingPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/onboarding/`,
    exact: true,
  })
  const isFrameAdvisorPLPPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/playlist/:playlistId`,
    exact: true,
  })
  const isFrameAdvisorScanningPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/fsa/scanning`,
    exact: true,
  })
  const isFrameAdvisorBiometricPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/onboarding/biometric-data-consent`,
    exact: true,
  })
  const isFrameAdvisorPrivacyPolicyPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/onboarding/privacy-policy`,
    exact: true,
  })
  const isFrameAdvisorBipaPolicyPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/onboarding/bipa-policy`,
    exact: true,
  })
  const isFrameAdvisorTermsConditionsPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/onboarding/terms-and-conditions`,
    exact: true,
  })
  const isFrameAdvisorSurveyPage = !!useRouteMatch({
    path: `${basePath}/frame-advisor/survey/:stepId?`,
    exact: true,
  })
  const isHomePage = !!useRouteMatch({ path: basePath, exact: true })
  const isPDPPage = !!useRouteMatch({ path: `${basePath}/products/:model/:moco/:upc`, exact: true })
  const isPLPPage = !!useRouteMatch({
    path: [
      `${basePath}/products`,
      `${basePath}/products/:contenId`,
      `${basePath}/frame-advisor/playlist/:playlistId`,
    ],
    exact: true,
  })
  const isSupernovaPage = !!useRouteMatch({ path: `${basePath}/supernova-home`, exact: false })
  const isThankYouV3Page = !!useRouteMatch({
    path: [`${basePath}/checkout-v3/thank-you`, `${basePath}/checkout-v3/myessilor/thank-you`],
    exact: true,
  })
  const isWishlistPage = !!useRouteMatch({ path: `${basePath}/wishlist`, exact: true })
  const isScanBarcodePage = !!useRouteMatch({ path: `${basePath}/scan-barcode`, exact: true })
  const isOrderCheckPage = !!useRouteMatch({
    path: `${basePath}/checkout-v3/order-check`,
    exact: true,
  })

  const pageChecker = {
    isBrandsPage,
    isCartPage,
    isCheckoutV3Page,
    isCustomPage,
    isFrameAdvisorPage,
    isFrameAdvisorOnboardingPage,
    isFrameAdvisorPLPPage,
    isFrameAdvisorScanningPage,
    isFrameAdvisorBiometricPage,
    isFrameAdvisorPrivacyPolicyPage,
    isFrameAdvisorBipaPolicyPage,
    isFrameAdvisorTermsConditionsPage,
    isFrameAdvisorSurveyPage,
    isHomePage,
    isPDPPage,
    isPLPPage,
    isSupernovaPage,
    isThankYouV3Page,
    isWishlistPage,
    isScanBarcodePage,
    isOrderCheckPage,
    isGensPage,
  }

  const currentPage = Object.entries(pageChecker).filter(page => page[1] === true)

  return {
    currentPage: currentPage.length ? currentPage[0][0] : 'isHomePage',
    ...pageChecker,
  } as PageChecker
}

export const useBackNavigation = () => {
  const history = useHistory()
  const { basePath } = useStoreIndentity()
  const { isFrameAdvisorScanningPage } = usePageChecker()

  const actions = useActions({
    clearFsa,
    setOnCaptureProcess,
  })

  if (isFrameAdvisorScanningPage) {
    if (
      !!(history.location.state as { fromBiometricDataConsent: boolean })?.fromBiometricDataConsent
    ) {
      return () => {
        FrameAdvisorCapture.close()
        history.goBack()
      }
    }

    return () => {
      FrameAdvisorCapture.close()
      actions.clearFsa()
      history.replace(`${basePath}/frame-advisor/onboarding`)
    }
  }

  return history.goBack
}
