import { ADD_ITEM, CartActions, REMOVE_ITEM, SET_ITEM_QUANTITY } from 'actions/cart'
import { CartItem } from 'types/cart'

export type CartState = {
  items: CartItem[]
}

const initialState: CartState = {
  items: [],
}

export default (state = initialState, action: CartActions) => {
  switch (action.type) {
    case ADD_ITEM: {
      const { payload } = action
      const newState = {
        items: state.items.map(el => ({
          id: el.id,
          product: { ...el.product },
          quantity: el.quantity,
          storeId: el.storeId,
        })),
      }

      const item = newState.items.find(v => v.id === payload.id)

      if (!item) {
        newState.items.push(payload)
      } else {
        // increment quantity in cart
        item.quantity += 1
      }

      return newState
    }

    case REMOVE_ITEM: {
      const { payload } = action
      const index = state.items.map(e => e.id).indexOf(payload.id)
      const newState = {
        ...state.items.splice(index, 1),
        items: [...state.items],
      }
      return newState
    }

    case SET_ITEM_QUANTITY: {
      const { payload } = action
      const index = state.items.map(e => e.id).indexOf(payload.id)
      const newState = { ...state, items: [...state.items] }

      newState.items[index].quantity = payload.quantity

      return newState
    }

    default:
      return state
  }
}
