import {
  hateItem,
  loveItem,
  removeItemFromMySelection,
  removeMoco,
  saveToUndoableList,
} from 'actions/frameAdvisor'
import { ThumbDownIcon, ThumbUpIcon } from 'components/core/Icons'
import { ThumbBtn } from 'components/core/ThumbBtn/styles'
import { useSendAnalyticsProductsEvent } from 'containers/AnalyticsProvider'
import { useActions } from 'hooks/useActions'
import { pxToRem } from 'libs/styled'
import React, { useCallback, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { LikeStatus, LoveHateThumbsProps } from 'types/frameAdvisor'
import { Product } from '../../../types/product'
import { LoveHateBtnContainer } from './styles'

const useIsProductRated = (product: Product, status: LikeStatus, playlistId?: string) => {
  const { loveHate } = useSelector(state => state.frameAdvisor)

  return useMemo(
    () =>
      !!loveHate.find(
        item =>
          item.moco === product.moco &&
          item.status === status &&
          (!playlistId || item.playListId === playlistId)
      ),
    [loveHate, product, playlistId, status]
  )
}

export const LoveHateActionsBtns: React.FC<LoveHateThumbsProps> = ({
  product,
  dataAttrs,
  playlistId,
  index = 0,
  isBlackAndWhite,
  showDislikeButton = true,
  scrollToTop,
}) => {
  const theme: DefaultTheme = useContext(ThemeContext)
  const isItemLiked = useIsProductRated(product, LikeStatus.LIKED, playlistId)
  const isItemDisliked = useIsProductRated(product, LikeStatus.DISLIKED, playlistId)
  const sendAddToWishlistEvent = useSendAnalyticsProductsEvent({
    id: 'ProdFavAdd',
  })

  const actions = useActions({
    loveItem,
    hateItem,
    removeItemFromMySelection,
    saveToUndoableList,
    removeMoco,
  })

  const handleLoveClick = useCallback(() => {
    sendAddToWishlistEvent && sendAddToWishlistEvent(product)
    if (isItemLiked) {
      actions.removeItemFromMySelection(product)
    } else {
      actions.loveItem(product, playlistId)
      scrollToTop && scrollToTop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isItemLiked])

  const handleHateClick = useCallback(() => {
    if (isItemDisliked) {
      actions.removeMoco(product, playlistId)
    } else {
      actions.hateItem(product, playlistId)
      actions.saveToUndoableList(product)
    }
  }, [actions, product, playlistId, isItemDisliked])

  const thumbUpIconColor = isBlackAndWhite ? theme.colors.black : theme.colors.green
  const thumbDownIconColor = isBlackAndWhite ? theme.colors.black : theme.colors.monza

  const renderLikeHateButtons = useCallback(() => {
    return (
      <>
        {isItemDisliked}
        {showDislikeButton && (
          <ThumbBtn
            onClick={handleHateClick}
            data-element-id={`Tiles_Tile${index}_ThumbDown`}
            data-test={`Tiles_Tile${index}_ThumbDown`}
            data-description={dataAttrs && dataAttrs.description}
            isActive={isItemDisliked}
            backgroundColor={isBlackAndWhite ? theme.colors.primaryText : theme.colors.monza}
          >
            <ThumbDownIcon
              stroke={isItemDisliked ? theme.cta.textColor : thumbDownIconColor}
              fill={isItemDisliked ? theme.cta.textColor : thumbDownIconColor}
              height={pxToRem(18)}
              width={pxToRem(19)}
            />
          </ThumbBtn>
        )}
        <ThumbBtn
          onClick={handleLoveClick}
          data-element-id={`Tiles_Tile${index}_ThumbUp`}
          data-test={`Tiles_Tile${index}_ThumbUp`}
          data-description={dataAttrs && dataAttrs.description}
          isActive={isItemLiked}
          backgroundColor={isBlackAndWhite ? theme.colors.primaryText : theme.colors.green}
        >
          <ThumbUpIcon
            stroke={isItemLiked ? theme.cta.textColor : thumbUpIconColor}
            fill={isItemLiked ? theme.cta.textColor : thumbUpIconColor}
            height={pxToRem(18)}
            width={pxToRem(19)}
          />
        </ThumbBtn>
      </>
    )
  }, [
    isItemDisliked,
    showDislikeButton,
    handleHateClick,
    index,
    dataAttrs,
    isBlackAndWhite,
    isItemLiked,
    theme,
    thumbDownIconColor,
    handleLoveClick,
    thumbUpIconColor,
  ])

  return <LoveHateBtnContainer>{renderLikeHateButtons()}</LoveHateBtnContainer>
}
