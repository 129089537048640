/* stylelint-disable */
import config from 'config'
import { css } from 'styled-components'

const AvenirLight = `${config.assets.URL}/fonts/avenir/light.woff`
const Avenir = `${config.assets.URL}/fonts/avenir/roman.woff`
const AvenirHeavy = `${config.assets.URL}/fonts/avenir/heavy.woff`
const AvenirNext = `${config.assets.URL}/fonts/avenir/next.ttf`

const Lato = `${config.assets.URL}/fonts/lato/lato.woff2`
const LatoLight = `${config.assets.URL}/fonts/lato/light.woff2`
const LatoThin = `${config.assets.URL}/fonts/lato/thin.ttf`
const LatoBold = `${config.assets.URL}/fonts/lato/bold.woff2`
const LatoBLack = `${config.assets.URL}/fonts/lato/black.ttf`
const LatoItalic = `${config.assets.URL}/fonts/lato/italic.ttf`
const LatoLightItalic = `${config.assets.URL}/fonts/lato/lightItalic.ttf`
const LatoThinItalic = `${config.assets.URL}/fonts/lato/thinItalic.ttf`
const LatoBoldItalic = `${config.assets.URL}/fonts/lato/boldItalic.ttf`
const LatoBLackItalic = `${config.assets.URL}/fonts/lato/blackItalic.ttf`

const Gilmer = `${config.assets.URL}/fonts/gilmer/gilmer.ttf`
const GilmerBold = `${config.assets.URL}/fonts/gilmer/bold.ttf`
const GilmerHeavy = `${config.assets.URL}/fonts/gilmer/heavy.ttf`
const GilmerLight = `${config.assets.URL}/fonts/gilmer/light.ttf`
const GilmerMedium = `${config.assets.URL}/fonts/gilmer/medium.otf`
const GilmerOutline = `${config.assets.URL}/fonts/gilmer/outline.ttf`

const Roboto = `${config.assets.URL}/fonts/roboto/roboto.ttf`
const RobotoBlack = `${config.assets.URL}/fonts/roboto/black.ttf`
const RobotoBold = `${config.assets.URL}/fonts/roboto/bold.ttf`
const RobotoLight = `${config.assets.URL}/fonts/roboto/light.ttf`
const RobotoThin = `${config.assets.URL}/fonts/roboto/thin.ttf`
const RobotoMedium = `${config.assets.URL}/fonts/roboto/medium.otf`
const RobotoItalic = `${config.assets.URL}/fonts/roboto/italic.ttf`
const RobotoBlackItalic = `${config.assets.URL}/fonts/roboto/blackItalic.ttf`
const RobotoBoldItalic = `${config.assets.URL}/fonts/roboto/boldItalic.ttf`
const RobotoLightItalic = `${config.assets.URL}/fonts/roboto/lightItalic.ttf`
const RobotoThinItalic = `${config.assets.URL}/fonts/roboto/thinItalic.ttf`
const RobotoMediumItalic = `${config.assets.URL}/fonts/roboto/mediumItalic.ttf`

const Orgon = `${config.assets.URL}/fonts/orgon/orgon.otf`
const OrgonBlack = `${config.assets.URL}/fonts/orgon/black.otf`
const OrgonBold = `${config.assets.URL}/fonts/orgon/bold.otf`
const OrgonLight = `${config.assets.URL}/fonts/orgon/light.otf`
const OrgonThin = `${config.assets.URL}/fonts/orgon/thin.otf`
const OrgonMedium = `${config.assets.URL}/fonts/orgon/medium.otf`
const OrgonItalic = `${config.assets.URL}/fonts/orgon/italic.otf`
const OrgonBlackItalic = `${config.assets.URL}/fonts/orgon/blackItalic.otf`
const OrgonBoldItalic = `${config.assets.URL}/fonts/orgon/boldItalic.otf`
const OrgonLightItalic = `${config.assets.URL}/fonts/orgon/lightItalic.otf`
const OrgonThinItalic = `${config.assets.URL}/fonts/orgon/thinItalic.otf`
const OrgonMediumItalic = `${config.assets.URL}/fonts/orgon/mediumItalic.otf`

const SourceSansPro = `${config.assets.URL}/fonts/sourcesans-pro/sourcesanspro.ttf`
const SourceSansProBold = `${config.assets.URL}/fonts/sourcesans-pro/bold.ttf`
const SourceSansProBlack = `${config.assets.URL}/fonts/sourcesans-pro/black.ttf`
const SourceSansProSemiBold = `${config.assets.URL}/fonts/sourcesans-pro/semiBold.ttf`
const SourceSansProSemiLight = `${config.assets.URL}/fonts/sourcesans-pro/light.ttf`

const Campton = `${config.assets.URL}/fonts/campton/campton-medium.ttf`
const Oswald = `${config.assets.URL}/fonts/oswald/oswald.ttf`
const Righteous = `${config.assets.URL}/fonts/righteous/rigtheous.woff2`
const ArtelyInks = `${config.assets.URL}/fonts/artely-inks/artely-inks.woff2`
const GaramondItalic = `${config.assets.URL}/fonts/garamond-italic/garamond-italic.ttf`

// prettier-ignore
export const fonts = css`

// #region Avenir
  @font-face {
    font-family: 'Avenir';
    src: url(${Avenir}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'AvenirLight';
    src: url(${AvenirLight}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Avenir';
    src: url(${AvenirHeavy}) format('woff');
    font-style: normal;
    font-weight: 900;
  }
  @font-face {
    font-family: 'Avenir';
    src: url(${AvenirNext}) format('truetype');
    font-style: normal;
    font-weight: normal;
  }
// #endregion

// #region Gilmer
  @font-face {
    font-family: 'Gilmer';
    src: url(${Gilmer}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url(${GilmerBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url(${GilmerHeavy}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url(${GilmerLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gilmer';
    src: url(${GilmerMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'GilmerOutline';
    src: url(${GilmerOutline}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

// #endregion

@font-face {
    font-family: 'Oswald';
    src: url(${Oswald}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

// #region Lato
  @font-face {
    font-family: 'Lato';
    src: url(${Lato}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
// #endregion

// #region SourceSansPro
  @font-face {
    font-family: 'SourceSansPro';
    src: url(${SourceSansPro}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url(${SourceSansProBold}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url(${SourceSansProBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url(${SourceSansProSemiLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'SourceSansPro';
    src: url(${SourceSansProSemiBold}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }


// #endregion

@font-face {
    font-family: 'Oswald';
    src: url(${Oswald}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

// #region Lato
  @font-face {
    font-family: 'Lato';
    src: url(${Lato}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoThin}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBold}) format('woff2');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBLack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoThinItalic}) format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Lato';
    src: url(${LatoBLackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
// #endregion

// #region Roboto
  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoThin}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoThinItalic}) format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoMediumItalic}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Roboto';
    src: url(${RobotoBlackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
// #endregion

// #region Orgon
  @font-face {
    font-family: 'Orgon';
    src: url(${Orgon}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonThin}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonItalic}) format('truetype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonThinItalic}) format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonMediumItalic}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  @font-face {
    font-family: 'Orgon';
    src: url(${OrgonBlackItalic}) format('truetype');
    font-weight: 900;
    font-style: italic;
  }
// #endregion

// #region ENGRAVING FONTS //
  @font-face {
    font-family: 'lato-regular';
    src: url(${Lato}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'righteous';
    src: url(${Righteous}) format('woff2');
  }

  @font-face {
    font-family: 'artely-inks';
    src: url(${ArtelyInks}) format('woff2');
  }

  @font-face {
    font-family: 'garamond-italic';
    src: url(${GaramondItalic}) format('truetype');
  }

  @font-face {
    font-family: 'Campton-Medium';
    src: url(${Campton}) format('truetype');
  }
// #endregion
`
