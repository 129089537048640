import { ProductTypeTogglerWrapper } from 'components/core/ProductTypeTogglerWrapper'
import { Heading2 } from 'components/Typography'
import { useStoreContext } from 'hooks/useStoreContext'
import { getKindFilterValue } from 'libs/filters'
import { Device, landscape, tablet } from 'libs/media'
import { clampValuesLandscape, pxToRem } from 'libs/styled'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { FiltersState } from 'types/filter'
import { getTitle } from './utils'

const filtersToRemoveFromPLPHeader = ['collection', 'polarized']

interface PLPTitleProps {
  filters: FiltersState
  className?: string
  hasItems?: boolean
  title?: string
  scrollToTop?: () => void
}

export const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-basis: 100%;
`

export const StickyTitle = styled.div`
  padding: ${pxToRem(12)} 0 ${pxToRem(44)} 0;
  background: ${({ theme }) => theme.colors.bodyBackground};
  position: sticky;
  top: -1px;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${tablet(`
    padding: ${pxToRem(31)} 0 ${pxToRem(20)};
    `)}
  ${landscape(Device.tablet, `padding: ${clampValuesLandscape(16, 34)} 0;`)}
`

export const PLPTitle = React.forwardRef<HTMLDivElement, PLPTitleProps>(
  ({ filters, className, hasItems, title, scrollToTop }, ref) => {
    const { t } = useTranslation()
    const location = useLocation()
    const store = useStoreContext()

    const enabledFilters = useMemo(
      () =>
        Object.values(filters).filter(filter => filter.options?.some(option => option.selected)),
      [filters]
    )

    const filteredEnabledFilters = enabledFilters.filter(
      filter => !filtersToRemoveFromPLPHeader.includes(filter.key)
    )

    const productTypeFilterValue = getKindFilterValue(location, 'collection')

    return (
      <StickyTitle className={className} ref={ref}>
        <HeadingWrapper>
          <Heading2>{title || getTitle(filteredEnabledFilters, store, location, t)}</Heading2>
          <ProductTypeTogglerWrapper
            filterValue={productTypeFilterValue}
            hasItems={hasItems}
            scrollToTop={scrollToTop}
          />
        </HeadingWrapper>
      </StickyTitle>
    )
  }
)

PLPTitle.displayName = 'PLPTitle'
