import { saveAnalyticsGoogleSended, saveAnalyticsTealiumSended } from 'actions/store'
import config from 'config'
import { useRefValue } from 'hooks/useRefValue'
import { useStoreContext } from 'hooks/useStoreContext'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import {
  EVENTS_ID,
  analyticsFormatCartItems,
  analyticsFormatProducts,
  formatCommonData,
  formatError,
  getStoreBrand,
  getStoreCustom,
  getStoreInitialCustomData,
  mapLoginNation,
} from 'libs/analytics'
import { sendGoogleAnalyticNewSession } from 'libs/googleAnalytics'
import { executeOnce } from 'libs/utils'
import { useReset } from 'providers/resetProvider'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  AnalitycsEventFunction,
  AnalyticsConfig,
  AnalyticsDataLayer,
  AnalyticsEventDataLayer,
  AnalyticsEventPayload,
  ErrorData,
  ErrorForAnalytics,
  StoreInitialCustomData,
} from 'types/analytics'
import { CartItem } from 'types/cart'
import { Maybe } from 'types/graphqlTypes'
import { CustomProduct } from 'types/product'
import { Store } from 'types/store'

declare global {
  interface Window {
    utag_data: AnalyticsDataLayer
    tealium_data2track: (AnalyticsEventDataLayer | ErrorForAnalytics)[]
  }
}

export const pushToDataTrack = (dataLayer: AnalyticsEventDataLayer | ErrorForAnalytics): void => {
  window.tealium_data2track = window.tealium_data2track || []
  window.tealium_data2track.push(dataLayer)

  //TODO: restore logger
  //Log.(`Analytics: sent event "${dataLayer.id}" with payload ${JSON.stringify(dataLayer)}`)
}
export const getEnv = (): string => {
  const hostname = window.location.hostname
  const qaEnvSlugs = ['-dev', '-test', '-uat', '-stage', 'localhost', 'test-', 'qa-', 'beta-']
  const isQaEnv = qaEnvSlugs.some(slug => hostname.includes(slug))
  return isQaEnv ? 'qa' : 'prod'
}

export const getAnalyticsScriptSrc = (analyticsProfile: string, sessionId: string): string => {
  return `//tags.tiqcdn.com/utag/luxottica/${analyticsProfile}/${getEnv()}/utag.js?v=${sessionId}`
}

export const loadAnalyticScript = (
  analyticsConfig: AnalyticsConfig,
  store: Maybe<Store> | undefined,
  sessionId: string,
  version: string,
  customData = {}
): void => {
  const { gtagId, profile, isEnabled } = analyticsConfig
  if (!isEnabled) return

  window.utag_data = {
    Adobe_SessionId: sessionId, // Session_Id?
    ...formatCommonData(analyticsConfig, store, version),
    ...customData,
  }

  const headFragment = document.createDocumentFragment()
  const gtag = document.createElement('script')

  gtag.id = gtagId
  gtag.text = `(function(a,b,c,d){
    a='${getAnalyticsScriptSrc(profile, sessionId)}';
    b=document;c='script';d=b.createElement(c);d.src=a;d.type='text/java'+c;d.async=true;
    a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a);
    })();
    `

  headFragment.appendChild(gtag)
  document.head.appendChild(headFragment)
}

export const useLoadAnalyticsScript = ({
  analyticsConfig,
  sessionId,
  store,
  version,
  error,
  customData = {},
}: {
  analyticsConfig: AnalyticsConfig
  sessionId: string
  store: Store | undefined
  version: string
  error?: unknown
  customData: StoreInitialCustomData
}): void => {
  useEffect(() => {
    if (sessionId) {
      if (store) {
        executeOnce(loadAnalyticScript, analyticsConfig.gtagId)(
          analyticsConfig,
          store,
          sessionId,
          version,
          customData
        )
      }

      if (error) {
        executeOnce(loadAnalyticScript, analyticsConfig.gtagId)(
          analyticsConfig,
          undefined,
          sessionId,
          version
        )
      }
    }
  }, [analyticsConfig, error, sessionId, store, version, customData])
}

export const sentAnalitycsError = (error: ErrorData): void => {
  pushToDataTrack(formatError(error))
}

export const AnalyticsContext = React.createContext({
  sendAnalyticsEvent: (
    _payload: AnalyticsEventPayload,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _addCommonData = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _isEventWithCatalogProducts = false
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
})

export const AnalyticsProvider: React.FC<{ analyticsConfig: AnalyticsConfig }> = ({
  analyticsConfig,
  children,
}) => {
  const sessionId = useSelector(state => state.session.token)
  const store = useStoreContext()

  const { storeId } = useStoreIndentity()
  const langCode: string | undefined = storeId.split('.')[2]
  const countryId: string | undefined = mapLoginNation[langCode]

  const storeRef = useRefValue({
    ...store,
    globalStoreId: store?.storeId,
    currency: {
      id: store?.numberFormat || 'EUR',
    },
    langCode: store?.langCode || 'en-US',
    countryId: countryId ? countryId : langCode?.toUpperCase(),
  })

  const storeForAnalytics = store && {
    ...store,
    globalStoreId: store.storeId,
    currency: {
      id: store.numberFormat || 'EUR',
    },
    langCode: store.langCode || 'en-US',
    countryId: countryId ? countryId : langCode?.toUpperCase(),
  }

  useLoadAnalyticsScript({
    analyticsConfig,
    sessionId,
    store: storeForAnalytics,
    version: config.version || '',
    customData: getStoreInitialCustomData(store),
  })

  const sendAnalyticsEvent = useCallback(
    (payload: AnalyticsEventPayload, addCommonData = false, isEventWithCatalogProducts = false) => {
      // We need to wait one tick to generate the object as we must
      // wait for all ref to be updated on some edge cases
      setTimeout(() => {
        const store = storeRef.current
        if (!analyticsConfig.isEnabled || !store) return

        const newDataLayer: AnalyticsEventDataLayer = addCommonData
          ? {
              ...getStoreInitialCustomData(store),
              ...formatCommonData(
                analyticsConfig,
                store,
                config.version || '',
                isEventWithCatalogProducts,
                payload
              ),
              Session_Id: sessionId,
              Fs_IsConnected: '',
              Page_UserFace: '',
              Page_UserStyle: '',
              Store_Brand: getStoreBrand(store),
              Store_Custom: getStoreCustom(store),
              Store_Chain: store.gviCode || '',
              ...payload,
            }
          : {
              Session_Id: sessionId,
              Fs_IsConnected: '',
              Page_UserFace: '',
              Page_UserStyle: '',
              ...payload,
            }
        pushToDataTrack(newDataLayer)
      }, 0)
    },
    [analyticsConfig, sessionId, storeRef]
  )

  // we need to memoize the context value to avoid sending
  // multiple events in useAnalyticsEvent
  const analyticsContextValue = useMemo(() => ({ sendAnalyticsEvent }), [sendAnalyticsEvent])

  return (
    <AnalyticsContext.Provider value={analyticsContextValue}>{children}</AnalyticsContext.Provider>
  )
}

/** Provide Common Data in sendEvent Callback Payload
 * @example
 * const sendTealiumNewSession = useSendAnalyticsEvent({ id: 'Start-Session' })
 * sendTealiumNewSession()
 */
export const useSendAnalyticsEvent = <T extends unknown[]>(
  payload: AnalyticsEventPayload | AnalitycsEventFunction<T>,
  addCommonData = false,
  isEventWithCatalogProducts = false
): ((...args: T) => void) => {
  const { sendAnalyticsEvent } = useContext(AnalyticsContext)

  const payloadRef = useRefValue(payload)

  return useCallback(
    (...args: T) => {
      const currentPayload = payloadRef.current
      if (typeof currentPayload === 'function') {
        return sendAnalyticsEvent(
          currentPayload(...args),
          addCommonData,
          isEventWithCatalogProducts
        )
      }
      const fromOldSession = history.state?.state?.fromOldSession
      const addTrafficCidParam = fromOldSession && currentPayload.id === EVENTS_ID.vpw
      const payload = addTrafficCidParam
        ? { ...currentPayload, Traffic_Cid: 'IT-XQO_SS' }
        : currentPayload
      sendAnalyticsEvent(payload, addCommonData, isEventWithCatalogProducts)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [payloadRef, sendAnalyticsEvent, addCommonData, isEventWithCatalogProducts]
  )
}

/** Provide Formatted Cart Items in Data Layer Effect
 * @example
 * useAnalyticsEvent({
 *   id: 'VirtualPage-View'
 * })
 */

const EMPTY_DEPTH = []

export const useAnalyticsEvent = (
  payload: AnalyticsEventPayload,
  deps: unknown[] = EMPTY_DEPTH,
  addCommonData = true
) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent(payload, addCommonData)

  useEffect(() => {
    sendAnalyticsEvent()
    // we can guarantee that all the dependency are satisfied as deps are additional dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent, ...deps])
}

/** Send Analytics error from callback */
export const useSendAnalyticsError = (error: ErrorData): (() => void) => {
  const errorRef = useRefValue(error)

  return useCallback(() => {
    sentAnalitycsError(errorRef.current)
  }, [errorRef])
}

/** Send Analytics error as Effect */
export const useAnalyticsError = (error: ErrorData): void => {
  const sendError = useSendAnalyticsError(error)
  useEffect(() => {
    sendError()
  }, [sendError])
}

/** Provide Formatted Products in sendEvent Callback Payload
 * @example
 * const sendRemoveItemsFromCart = useSendAnalyticsProductsEvent({
 *  id: 'Prods-Delete',
 * })
 * sendRemoveItemsFromCart(product)
 */
export const useSendAnalyticsProductsEvent = (
  payload: AnalyticsEventPayload,
  addCommonData = false
): ((products: CustomProduct | CustomProduct[]) => void) => {
  return useSendAnalyticsEvent((products: CustomProduct | CustomProduct[]) => {
    const productList = Array.isArray(products) ? products : [products]
    return {
      Products: analyticsFormatProducts(productList),
      ...payload,
    }
  }, addCommonData)
}

/** Hook to send analytics addToCart event */
export const useSendAnalyticsAddToCartEvent = (
  payload: AnalyticsEventPayload,
  addCommonData = false
) /*: ((products: CustomProduct | CustomProduct[]) => void)*/ => {
  const sendAddToCartEvent = useSendAnalyticsEvent((products: CustomProduct | CustomProduct[]) => {
    const productList = Array.isArray(products) ? products : [products]
    const prods = analyticsFormatProducts(productList)

    Object.keys(prods).forEach((key: string) => {
      delete prods[key]['Vm_IsUpcSupported']
      delete prods[key]['Conf_IsUpcSupported']
    })
    return {
      Products: prods,
      id: 'AddToCart',
      ...payload,
    }
  }, addCommonData)

  const sendVMAddToCartEvent = useSendAnalyticsEvent(() => {
    return {
      id: 'Click',
      data_element_id: 'VirtualMirror_AddCart',
    }
  }, addCommonData)

  return {
    sendAddToCartEvent,
    sendVMAddToCartEvent,
  }
}

/** Provide Formatted Cart Items in Data Layer Effect
 * @example
 * useAnalyticsCartEvent({
 *   id: 'VirtualPage-View'
 * }, items)
 */
export const useAnalyticsCartEvent = (
  payload: AnalyticsEventPayload,
  items: CartItem[],
  deps: unknown[] = EMPTY_DEPTH,
  addCommonData = true
) => {
  const sendAnalyticsEvent = useSendAnalyticsEvent(
    {
      Products: analyticsFormatCartItems(items),
      ...payload,
    },
    addCommonData
  )

  useEffect(() => {
    sendAnalyticsEvent()
    // we can guarantee that all the dependency are satisfied as deps are additional dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendAnalyticsEvent, ...deps])
}

export const useAnalyticsNewSession = (store: Maybe<Store>): void => {
  const { userInteractedAfterReset } = useReset()
  const dispatch = useDispatch()
  const hasSentGoogleAnalyticsNewSession = useSelector(
    s => s.session.hasSentGoogleAnalyticsNewSession
  )
  const hasSentTealiumNewSession = useSelector(s => s.session.hasSentTealiumNewSession)

  const sendTealiumNewSession = useSendAnalyticsEvent(
    {
      id: 'VirtualPage-View',
      Session_Status: 'Off',
      Events_SessionStart: '1',
    },
    true
  )

  useEffect(() => {
    if (store && userInteractedAfterReset) {
      executeOnce(() => {
        if (!hasSentGoogleAnalyticsNewSession) {
          sendGoogleAnalyticNewSession(store)
          dispatch(saveAnalyticsGoogleSended())
        }
      }, 'start-session-google')()
    }
  }, [dispatch, hasSentGoogleAnalyticsNewSession, store, userInteractedAfterReset])

  useEffect(() => {
    if (store) {
      executeOnce(() => {
        if (!hasSentTealiumNewSession) {
          sendTealiumNewSession()
          dispatch(saveAnalyticsTealiumSended())
        }
      }, 'start-session-tealium')()
    }
    // we need to wait for store as events before store are dropped
  }, [store, sendTealiumNewSession, hasSentTealiumNewSession, dispatch])
}
