import { setVisibleDigitalCouvette, toggleMenuDrawer } from 'actions/ui'
import ConditionalRender from 'components/ConditionalRender'
import { LanguageSelector } from 'components/LanguageSelector'
import Loading from 'components/Loading'
import {
  ArrowLeftIcon,
  BarcodeIcon,
  FrameAdvisorIcon,
  ResetIcon,
  ThumbUpPlain,
} from 'components/core/Icons'
import { HandoffIcon } from 'components/core/Icons/HandoffIcon'
import { HomeIconMenu } from 'components/core/Icons/Home'
import { Modal } from 'components/core/Modal'
import { PanelHeader } from 'components/core/PanelHeader'
import config from 'config'
import { languages } from 'data'
import { useAction } from 'hooks/useActions'
import { useBarcodeScanner } from 'hooks/useBarcodeScanner'
import { useModalHandler } from 'hooks/useModalHandler'
import { useStoreContext } from 'hooks/useStoreContext'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { useGetSortedBrands } from 'libs/brand'
import { createMenuContent } from 'libs/menu'
import { isBarcodeAvailiable, isIPadView } from 'libs/utils'
import { useReset } from 'providers/resetProvider'
import React, { Suspense, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { PrimaryButton, SecondaryButton } from '../core/Button'
import { MenuContent, MenuItem } from './MenuContent'
import { MenuItemButton, MenuItemLink } from './MenuItemButton'
import {
  BackButton,
  HeadMenuBorderItem,
  HeadMenuItem,
  HeadMenuList,
  MenuContainer,
  MenuIconBadge,
  MenuPanelHeader,
  ModalContent,
  ModalContentResetCTAContainer,
  ModalContentResetContainer,
  ScrollingContent,
  ThumbUpIconWrapper,
} from './styles'
import { pxToRem } from 'libs/styled'
import { useFilteredEyewearBrands } from 'hooks/useFilteredEyewearBrands'
import { DigitalCouvetteIcon } from 'components/core/Icons/DigitalCouvette'

export const MainMenu: React.FC = () => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const history = useHistory()
  const store = useStoreContext()

  const theme: DefaultTheme = useContext(ThemeContext)
  const { reset } = useReset()
  const isTablet = isIPadView()
  const { toggleBarcodeScanner } = useBarcodeScanner()

  const { setVisibleModal } = useModalHandler('transferSessionModal')
  const dispatch = useDispatch()

  const eyeWearBrands = useFilteredEyewearBrands()

  const sortedBrands = useGetSortedBrands(eyeWearBrands)

  const formattedMenuContent = useMemo(
    () => createMenuContent(store.menu, sortedBrands, basePath),
    [basePath, sortedBrands, store.menu]
  )
  const menuContent = store?.menu ? formattedMenuContent : []
  const isFrameAdvisorEnabled = store?.frameAdvisor
  const hideMenuDrawer = useAction(toggleMenuDrawer)

  const [menuIsCreated, setMenuIsCreated] = useState(false)
  const [currentMenuGroup, setCurrentMenuGroup] = useState(menuContent[0])
  const [historyMenuGroup, setHistoryMenuGroup] = useState<MenuItem[]>([menuContent[0]])
  const [isResetModalVisible, setIsResetModalVisible] = useState(false)

  const languageSelectorIsVisible = useSelector(({ ui }) => ui.languageSelectorIsVisible)
  const isTopLevel = historyMenuGroup.length === 1

  if (!menuIsCreated && menuContent.length > 0) {
    setMenuIsCreated(true)
    setCurrentMenuGroup(menuContent[0])
    setHistoryMenuGroup([menuContent[0]])
  }

  const goBackMenu = useCallback(() => {
    historyMenuGroup.pop()
    const menuItem = historyMenuGroup[historyMenuGroup.length - 1]
    setCurrentMenuGroup(menuItem)
    setHistoryMenuGroup([...historyMenuGroup])
  }, [historyMenuGroup])

  const isHomePage = Boolean(
    useRouteMatch({
      path: basePath,
      exact: true,
    })
  )

  const openMenu = useCallback(
    (menu: MenuItem) => {
      setHistoryMenuGroup([...historyMenuGroup, menu])
      setCurrentMenuGroup(menu)
    },
    [historyMenuGroup]
  )

  const resetNavigation = () => {
    reset()
    setIsResetModalVisible(false)
  }

  const handleBarcodeClick = () => {
    toggleBarcodeScanner()
    hideMenuDrawer()
  }

  const numberOfWishlistItems = useSelector(({ wishlist }) => wishlist.items.length)

  const { fsaUserId } = useSelector(state => state.frameAdvisor)
  const userProfile = useSelector(s => s.frameAdvisor.fsaInfo)
  const FAOnboardingUrl = `${basePath}/frame-advisor/onboarding`
  const FAProfileUrl = `${basePath}/frame-advisor/profile/${fsaUserId}`

  const FrameAdvisorUrl = userProfile && fsaUserId ? FAProfileUrl : FAOnboardingUrl

  const isSessionTransferAvailable = !isIPadView() && !!config.toggleFeature.handoff

  const isIpad = isIPadView()

  return (
    <Suspense fallback={<Loading />}>
      {currentMenuGroup && (
        <MenuContainer>
          <MenuPanelHeader
            isLangSelectorVisible={!!languageSelectorIsVisible}
            isTopLevel={!!isTopLevel}
          >
            <PanelHeader onClose={hideMenuDrawer}>
              {historyMenuGroup.length > 1 ? (
                <div>
                  <BackButton type="button" onClick={goBackMenu} data-analytics_available_call="0">
                    <ArrowLeftIcon stroke={theme.menu.textColor} />
                  </BackButton>
                </div>
              ) : (
                <LanguageSelector languages={languages} />
              )}
            </PanelHeader>
          </MenuPanelHeader>

          {!languageSelectorIsVisible && (
            <ScrollingContent>
              {historyMenuGroup.length < 2 && (
                <HeadMenuList>
                  <HeadMenuItem>
                    <MenuItemButton
                      dataAttr="MainNav_MenuReset"
                      icon={<ResetIcon stroke={theme.menu.textColor} />}
                      label={t('Core.menuReset')}
                      onClick={() => setIsResetModalVisible(true)}
                    />
                  </HeadMenuItem>

                  {!isHomePage && (
                    <HeadMenuItem>
                      <MenuItemButton
                        dataAttr="MainNav_MenuHomePage"
                        icon={<HomeIconMenu fill={theme.menu.textColor} />}
                        label="Home Page"
                        onClick={() => history.push(basePath)}
                      />
                    </HeadMenuItem>
                  )}

                  {isTablet && isBarcodeAvailiable() && (
                    <HeadMenuItem>
                      <MenuItemButton
                        dataAttr="MainNav_MenuBarcode"
                        icon={<BarcodeIcon width="60%" fill={theme.menu.textColor} />}
                        label={t('NavBar.barcode')}
                        onClick={handleBarcodeClick}
                      />
                    </HeadMenuItem>
                  )}
                  <ConditionalRender condition={isIpad}>
                    <HeadMenuItem>
                      <MenuItemButton
                        dataAttr="MainNav_Menu_DigitalCouvette"
                        icon={
                          <DigitalCouvetteIcon
                            fill={theme.menu.textColor}
                            width={pxToRem(32)}
                            height={pxToRem(32)}
                          />
                        }
                        label={t('NavBar.digitalCouvette')}
                        onClick={() => dispatch(setVisibleDigitalCouvette(true))}
                      />
                    </HeadMenuItem>
                  </ConditionalRender>
                  {isFrameAdvisorEnabled && (
                    <HeadMenuItem>
                      <MenuItemLink
                        dataAttr="MainNav_MenuFrameADvisor"
                        icon={
                          <FrameAdvisorIcon
                            width={pxToRem(32)}
                            height={pxToRem(32)}
                            fill={theme.menu.textColor}
                          />
                        }
                        label={t('NavBar.frameadvisor')}
                        to={FrameAdvisorUrl}
                      />
                    </HeadMenuItem>
                  )}
                  <HeadMenuItem>
                    <MenuItemLink
                      dataAttr="MainNav_MenuFittingR"
                      icon={
                        <ThumbUpIconWrapper>
                          <ThumbUpPlain fill={theme.menu.textColor} />
                          {numberOfWishlistItems > 0 ? <MenuIconBadge /> : null}
                        </ThumbUpIconWrapper>
                      }
                      label={t('NavBar.wishlist')}
                      to={`${basePath}/wishlist`}
                    />
                  </HeadMenuItem>
                  <ConditionalRender condition={isSessionTransferAvailable}>
                    <HeadMenuBorderItem>
                      <MenuItemButton
                        dataAttr="MainNav_Handoff"
                        icon={<HandoffIcon width="1rem" height="auto" />}
                        label={t('NavBar.handoff')}
                        onClick={() => setVisibleModal('transferSessionModal')}
                      />
                    </HeadMenuBorderItem>
                  </ConditionalRender>
                </HeadMenuList>
              )}

              <MenuContent
                menu={currentMenuGroup}
                onSelect={openMenu}
                isTopLevel={historyMenuGroup.length === 1}
              />
            </ScrollingContent>
          )}
        </MenuContainer>
      )}
      <Modal
        visible={isResetModalVisible}
        onCancel={() => {
          setIsResetModalVisible(false)
        }}
        dataCalls="0"
      >
        <ModalContent>
          <ModalContentResetContainer>
            <span>{t('Reset.content')}</span>
          </ModalContentResetContainer>
          <ModalContentResetCTAContainer>
            <SecondaryButton
              onClick={() => setIsResetModalVisible(false)}
              data-analytics_available_call="0"
            >
              {t('Reset.ctaCancel')}
            </SecondaryButton>
            <PrimaryButton
              data-element-id="MainNav_MenuResetYes"
              onClick={resetNavigation}
              data-reset
            >
              {t('Reset.ctaConfirm')}
            </PrimaryButton>
          </ModalContentResetCTAContainer>
        </ModalContent>
      </Modal>
    </Suspense>
  )
}
