import ErrorWidget from 'components/ErrorWidget'
import Loading from 'components/Loading'
import { ThemeUpdateContext } from 'context/themeContext'
import { useStoreData } from 'data'
import createDebug from 'debug'
import { parseGrapqhQLError } from 'libs/errors'
import React, { useContext } from 'react'
import { theme } from 'theme'
import { Store } from 'types/graphqlTypes'

export interface WindowDimensions {
  height: number
  width: number
}

interface StoreContextData {
  store: Store
}

export const StoreContext = React.createContext<StoreContextData>({
  store: {
    id: '',
    deviceId: '',
    url: '',
    langCode: '',
    storeId: '',
    globalStoreId: '',
    countryId: '',
    storeType: '',
    logo: null,
    brands: [],
    vtoEnabled: true,
    rtrEnabled: true,
    screensaverEnabled: false,
    frameAdvisor: false,
    screensaverUrls: [],
    courtesy: false,
    digitalCatalog: false,
    deviceType: '',
    userStatus: '',
    username: '',
    srpStandard: false,
    srpCustom: false,
    storeLogo: null,
    styleGuide: {
      BodyPrimaryTextColor: '',
      CtaBackgroundColor: '',
      MenuTextColor: '',
      CtaTextAlignment: '',
      BodyBackgroundColor: '',
      CtaTextColor: '',
      MenuBackgroundColor: '',
      BodyFontFamily: '',
      CtaBorderRadius: '',
      BodySecondaryTextColor: '',
      CtaSecondaryTextColor: '',
      CtaSecondaryBackgroundColor: '',
      CtaSecondaryTextAlignment: '',
      CtaSecondaryBorderRadius: '',
    },
    remixEnabled: false,
    ocpEnabled: false,
    filteredBrandsBySunCategory: [],
    filteredBrandsByOpticalCategory: [],
    addressGeocode: false,
    shippingMethods: [
      {
        code: '',
        translationKey: '',
        estimations: {
          min: '',
          max: '',
        },
        fee: 0,
        upc: '',
        destinationAvailability: {
          home: false,
          store: false,
        },
      },
    ],
    states: [],
    shipToConsumerEnabled: false,
  },
})

const debugError = createDebug('error:store')

export const StoreProvider: React.FC = ({ children }) => {
  const { data, loading, error } = useStoreData()

  const updateTheme = useContext(ThemeUpdateContext)

  if (error) {
    debugError(error)
    return <ErrorWidget {...parseGrapqhQLError(error)} withWrapper />
  }

  if (!data?.store || loading) return <Loading />

  if (data.store.styleGuide) {
    const styleGuide = data.store.styleGuide
    updateTheme({
      ...theme,
      fonts: {
        ...theme.fonts,
        primary: styleGuide.BodyFontFamily || 'Gilmer, sans-serif',
      },
      colors: {
        ...theme.colors,
        primaryText: styleGuide.BodyPrimaryTextColor || '#333333',
        secondaryText: styleGuide.BodySecondaryTextColor || '#555555',
        bodyBackground: styleGuide.BodyBackgroundColor || '#ffffff',
      },
      cta: {
        textColor: styleGuide.CtaTextColor || 'white',
        backgroundColor: styleGuide.CtaBackgroundColor || '#031434',
        textAlignment: styleGuide.CtaTextAlignment || 'center',
        borderRadius: styleGuide.CtaBorderRadius || '0px',
        secondaryTextColor: styleGuide.CtaSecondaryTextColor || '#333333',
        secondaryBackgroundColor: styleGuide.CtaSecondaryBackgroundColor || 'transparent',
        secondaryTextAlignment: styleGuide.CtaSecondaryTextAlignment || 'center',
        secondaryBorderRadius: styleGuide.CtaSecondaryBorderRadius || '0px',
      },
      menu: {
        textColor: styleGuide.MenuTextColor || '#333333',
        backgroundColor: styleGuide.MenuBackgroundColor || '#f2f2f2',
      },
    })
  }

  return <StoreContext.Provider value={{ store: data.store }}>{children}</StoreContext.Provider>
}
