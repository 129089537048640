import { toggleFiltersDrawer, toggleMenuDrawer, toggleSearchDrawer } from 'actions/ui'
import ConditionalRender from 'components/ConditionalRender'
import { ToolTipTimeout } from 'components/Tooltip/ToolTipTimeout'
import {
  BackIcon,
  CloseIcon,
  FilterIcon,
  HamburgerMenuIcon,
  HomeIcon,
  SearchIcon,
  ShoppingBagIcon,
} from 'components/core/Icons'
import { HandoffIcon } from 'components/core/Icons/HandoffIcon'
import { TransferSessionModal } from 'components/core/Modal/TransferSessionModal'
import config from 'config'
import { useAction } from 'hooks/useActions'
import { useBarcodeScanner } from 'hooks/useBarcodeScanner'
import { useCart } from 'hooks/useCart'
import { useModalHandler } from 'hooks/useModalHandler'
import { useBackNavigation, usePageChecker } from 'hooks/useNavigationUtils'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import { isIPadView } from 'libs/utils'
import { NavigationContext } from 'providers/navigationProvider'
import { Children, default as React, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FloatingButton, FloatingButtonInner, FloatingContainer, MenuIconBadge } from './styles'

export const CloseButton: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <FloatingButton type="button" onClick={onClose} data-analytics_available_call="0">
      <FloatingButtonInner>
        <CloseIcon />
      </FloatingButtonInner>
    </FloatingButton>
  )
}

interface FloatingActionsProps {
  withoutBackButton?: boolean
  storeType?: string
  digitalCatalog?: boolean
  showBackButton?: boolean
}

export const FloatingActions: React.FC<FloatingActionsProps> = ({ showBackButton = false }) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const showMenu = useAction(toggleMenuDrawer)
  const showFilters = useAction(toggleFiltersDrawer)
  const showSearch = useAction(toggleSearchDrawer)
  const history = useHistory()
  const filtersCount = useSelector(s => s.ui.filtersCount)
  const { numberItems } = useCart()
  const numberOfWishlistItems = useSelector(({ wishlist }) => wishlist.items.length > 0)
  const isFsaConnected = useSelector(({ frameAdvisor }) => frameAdvisor.fsaVideoId)
  const { landscapeNavigation } = useContext(NavigationContext)
  const { currentPage, isCartPage, isCheckoutV3Page } = usePageChecker()
  const showMenuBagde = numberOfWishlistItems || isFsaConnected
  const isSessionTransferAvailable = !!config.toggleFeature.handoff
  const {
    setVisibleModal,
    isVisible: transferSessionModalIsVisible,
    closeAnyModal,
  } = useModalHandler('transferSessionModal')
  const showHandoffTooltip = isCartPage || isCheckoutV3Page

  const goBack = useBackNavigation()

  const isIPad = isIPadView()
  const iconSize = useMemo(() => {
    if (isIPad) {
      return clampValues(20, 24)
    }
    if (isIPad && landscapeNavigation) {
      return clampValuesLandscape(20, 24)
    }
    return pxToRem(32)
  }, [isIPad, landscapeNavigation])

  const { toggleBarcodeScanner } = useBarcodeScanner()
  const handleTransferClick = useCallback(() => {
    if (isIPad && isSessionTransferAvailable) {
      toggleBarcodeScanner()
    } else {
      setVisibleModal('transferSessionModal')
    }
  }, [isIPad, isSessionTransferAvailable, setVisibleModal, toggleBarcodeScanner])

  const Back = (
    <ConditionalRender condition={showBackButton}>
      <FloatingButton
        data-element-id="MainNav_Back"
        data-test="MainNav_Back"
        onClick={goBack}
        key="floating-action-back-button"
      >
        <BackIcon height={iconSize} width={iconSize} />
      </FloatingButton>
    </ConditionalRender>
  )

  const CheckoutV3Back = (
    <FloatingButton
      data-element-id="MainNav_Back"
      data-test="MainNav_Back"
      onClick={goBack}
      key="floating-action-back-button"
      data-analytics_available_call="0"
    >
      <BackIcon height={iconSize} width={iconSize} />
    </FloatingButton>
  )

  const Home = (
    <FloatingButton
      key="floating-action-home-button"
      data-element-id="MainNav"
      data-test="MainNav"
      onClick={() => history.push(basePath)}
    >
      <HomeIcon height={iconSize} width={iconSize} />
    </FloatingButton>
  )

  const Search = (
    <FloatingButton
      key="floating-action-search-button"
      data-element-id="MainNav_Search"
      data-test="MainNav_Search"
      onClick={showSearch}
    >
      <SearchIcon height={iconSize} width={iconSize} />
    </FloatingButton>
  )

  const Menu = (
    <FloatingButton
      data-element-id="MainNav_Menu"
      data-test="MainNav_Menu"
      onClick={showMenu}
      key="floating-action-menu-button"
    >
      <HamburgerMenuIcon height={iconSize} width={iconSize} />

      {showMenuBagde && <MenuIconBadge />}
    </FloatingButton>
  )

  const ShoppingBag = (
    <FloatingButton
      data-element-id="MainNav_Bag"
      key="floating-action-shopping-bag-button"
      data-test="MainNav_Bag"
      onClick={() => history.push(`${basePath}/cart`)}
    >
      <ShoppingBagIcon height={iconSize} width={iconSize} />
      {numberItems > 0 ? <MenuIconBadge /> : null}
    </FloatingButton>
  )

  const Filters = (
    <FloatingButton
      data-element-id="MainNav_Filters"
      key="floating-action-filters-button"
      data-test="MainNav_Filters"
      onClick={showFilters}
    >
      <FilterIcon height={iconSize} width={iconSize} />
      {filtersCount > 1 && <MenuIconBadge />}
    </FloatingButton>
  )

  const Handoff = !isIPadView() && isSessionTransferAvailable && (
    <ToolTipTimeout text={t('Menu.sessionTransfer')} visible={showHandoffTooltip}>
      <FloatingButton
        data-element-id="MainNav_Handoff"
        key="floating-action-Handoff-button"
        data-test="MainNav_Handoff"
        onClick={handleTransferClick}
      >
        <HandoffIcon height={iconSize} width={iconSize} />
      </FloatingButton>
    </ToolTipTimeout>
  )

  const FloatingActionsMap: Record<string, JSX.Element[]> = {
    isCheckoutV3Page: [CheckoutV3Back, Search, Menu, ShoppingBag, Handoff],
    isOrderCheckPage: [CheckoutV3Back, Handoff],
    isBrandsPage: [Back, Search, Menu, ShoppingBag, Handoff],
    isCartPage: [Back, Search, Menu, ShoppingBag, Handoff],
    isCustomPage: [Back],
    isFrameAdvisorOnboardingPage: [Back, Search, Menu],
    isFrameAdvisorScanningPage: [Back],
    isFrameAdvisorBiometricPage: [Back],
    isFrameAdvisorPrivacyPolicyPage: [Back],
    isFrameAdvisorBipaPolicyPage: [Back],
    isFrameAdvisorTermsConditionsPage: [Back],
    isFrameAdvisorSurveyPage: [Back, Search, Menu],
    isHomePage: [Search, Menu, ShoppingBag, Handoff],
    isPDPPage: [Back, Search, Menu, ShoppingBag, Handoff],
    isPLPPage: [Back, Search, Menu, ShoppingBag, Handoff, Filters],
    isFrameAdvisorPLPPage: [Back, Search, Menu, ShoppingBag, Handoff, Filters],
    isWishlistPage: [Back, Search, Menu, ShoppingBag, Handoff],
    isStellaPage: [Home],
    isSupernovaPage: [Home],
    isGensPage: [Back, Search, Menu, ShoppingBag, Handoff],
    fallback: [Back, Search, Menu, ShoppingBag, Handoff],
  }
  return (
    <>
      <FloatingContainer>
        {FloatingActionsMap[currentPage]
          ? Children.toArray(
              FloatingActionsMap[currentPage].map(
                (FloatingActionButton: JSX.Element) => FloatingActionButton
              )
            )
          : Children.toArray(
              FloatingActionsMap['fallback'].map(
                (FloatingActionButton: JSX.Element) => FloatingActionButton
              )
            )}
      </FloatingContainer>
      <ConditionalRender condition={isSessionTransferAvailable}>
        <TransferSessionModal visible={transferSessionModalIsVisible} onClose={closeAnyModal} />
      </ConditionalRender>
    </>
  )
}
