import { useGetProductBrandName } from 'libs/utils'
import * as React from 'react'
import styled from 'styled-components'
import { pxToRem } from '../../libs/styled'
import { CartItem } from '../../types/cart'
import { ProductCard } from '../CardProductBase'
import { MiniCartPrice } from '../MiniCartPrice'

interface MinicartProductCardProps {
  cartItem: CartItem
  handleRemoveButtonClick: () => void
  showPrice: boolean

  showRTRIcon: boolean
}

const MiniCartPriceStyled = styled(MiniCartPrice)`
  max-width: ${pxToRem(150)};
`

export const MinicartProductCard: React.FC<MinicartProductCardProps> = ({
  cartItem,
  handleRemoveButtonClick,
  showPrice,
  showRTRIcon,
}) => {
  const { product } = cartItem
  const { UPC, brand, name } = product
  const getProductBrandName = useGetProductBrandName()

  const dataAttrs = {
    link: 'MainNav_BagProd-Img',
    removeBtn: 'MainNav_BagProd-Remove',
    description: `${getProductBrandName(brand.code)}_${name}_${UPC}`,
  }
  return (
    <ProductCard
      product={product}
      dataAttrs={dataAttrs}
      handleRemoveButtonClick={handleRemoveButtonClick}
      showRemoveButton
      showProductModel
      showIcons={true}
      priceStyle="default"
      showRTRIcon={showRTRIcon}
      isOppositeIconArrangement={true}
      customPrice={
        <MiniCartPriceStyled
          showPrice={showPrice}
          quantity={cartItem.quantity}
          cartItem={cartItem}
          showQuantity
          showVAT
          showSuggested
        />
      }
    />
  )
}
