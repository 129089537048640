import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import styled from 'styled-components'
import { IRTRStyle } from 'types/RealTimeRenderer'
import { SecondaryButton } from '../Button'

export const RTRContainer = styled.div<{ isActive: boolean; styleType: IRTRStyle }>`
  width: 100%;
  height: 100%;
  z-index: ${({ isActive }) => (isActive ? 2 : 'auto')};

  ${({ styleType }) =>
    styleType === IRTRStyle.pdp
      ? `
        position: absolute;
        margin-top: ${pxToRem(56)};
        ${tablet(`margin-top: ${pxToRem(48)};`)}
    `
      : `
        position:relative;
    `}
`

export const RTRWrapper = styled.div<{ isActive: boolean }>`
  position: relative;
  top: ${pxToRem(20)};
  height: 100%;
  width: 100%;
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  margin: 0 auto;
  width: ${pxToRem(950)};
  max-height: ${pxToRem(780)};
  ${tablet(`
    width: ${clampValues(624, 832)};
    top: ${pxToRem(25)};
  `)}
  ${tablet(
    Device.ipadMini,
    `
    height: ${pxToRem(500)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    width: ${clampValuesLandscape(624, 832)};
    height: ${clampValuesLandscape(400, 535)};
    `
  )}
`

export const RTRButton = styled(SecondaryButton)<{
  isActive: boolean
  styleType: IRTRStyle
}>`
  background: ${({ isActive, theme }) =>
    isActive ? theme.cta.backgroundColor : theme.cta.secondaryBackgroundColor};
  color: ${({ isActive, theme }) =>
    isActive ? theme.cta.textColor : theme.cta.secondaryTextColor};
  display: inline;
  padding: ${pxToRem(12)} ${pxToRem(21)};
  height: auto;
  max-height: ${pxToRem(372)};

  svg {
    margin-left: ${pxToRem(20)};

    &.icon--ThreeSixty {
      margin-right: ${pxToRem(20)};
      margin-left: 0;
    }
  }

  svg > path {
    fill: ${({ isActive, theme }) =>
      isActive ? theme.cta.textColor : theme.cta.secondaryTextColor};
  }

  span {
    padding: 0 ${pxToRem(15)};
    line-height: ${pxToRem(36)};
  }
  ${({ styleType }) =>
    styleType === IRTRStyle.pdp
      ? `
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        z-index:1;
    `
      : `

    `}
`
