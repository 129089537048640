import blackLogo from 'assets/LX_SmartShopper_Logo_black.png'
import whiteLogo from 'assets/LX_SmartShopper_Logo_white.png'
import { useStoreContext } from 'hooks/useStoreContext'
import { noStoreLogo } from 'libs/image'
import { tablet } from 'libs/media'
import React from 'react'
import styled from 'styled-components'

type logoColors = {
  black: string
  white: string
}

const logoColors: logoColors = {
  black: blackLogo,
  white: whiteLogo,
}

export type LogoColors = keyof logoColors

const LogoImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
  max-height: 8em;
  max-width: 100%;
  margin: auto;
  ${tablet(`
    max-height: 5em;
  `)}
`

export const Logo: React.FC<{ logoColor?: string }> = ({ logoColor = 'black' }) => {
  const store = useStoreContext()
  const { storeLogo, storeType, logo } = store
  let src = !!storeLogo ? storeLogo[logoColor] : logoColors[logoColor]

  if (
    !!storeLogo &&
    noStoreLogo(storeLogo) &&
    ['oo', 'rb'].includes(storeType.toLocaleLowerCase())
  ) {
    src = logo.src
  }

  if (storeLogo?.custom) {
    src = storeLogo.custom
  }

  return <LogoImg src={src} alt="" className={storeType} />
}
