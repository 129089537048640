import { setAvailableInStore } from 'actions/filters'
import { setFiltersCount, updatePreselectedFilters } from 'actions/ui'
import {
  CATEGORIES,
  DEFAULT_FILTERS,
  DefaultFilters,
  FilterKind,
} from 'components/FiltersDrawer/filters.const'
import config from 'config'
import { ActiveFilter } from 'context/filtersContext'
import { History, Location as LocationHistory } from 'history'
import {
  getConformedBrandsList,
  getKindFilterValue,
  getMainBrandFromSubBrands,
  isInArrayOrEqual,
  isInBrandArray,
  toArray,
} from 'libs/filters'
import { getPLPFiltersKindAndValueFromLocation } from 'libs/url'
import { executeOnce, uniqueId } from 'libs/utils'
import { groupBy } from 'lodash'
import qs from 'qs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ActiveFilters } from 'types/analytics'
import { FilterFacet, FilterOption, FiltersFromUrl, FiltersState } from 'types/filter'
import { useActions } from './useActions'
import { useStoreContext } from './useStoreContext'

const applyFilters = (filtersFromUrl: FiltersFromUrl, history: History) => {
  const newSearch = qs.stringify(filtersFromUrl)
  history.replace({ ...location, search: newSearch })
}

export const useFiltersFromUrl = () => {
  const {
    location: { search },
  } = useHistory()
  return useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]) as FiltersFromUrl
}

export const useToggleUrlFilter = (kind: keyof FiltersFromUrl, value: string, replace = false) => {
  const history = useHistory()
  const store = useStoreContext()
  const brands = getConformedBrandsList(store.brands)

  const actions = useActions({
    setAvailableInStore,
  })

  return useCallback(() => {
    const { location } = history
    const { search } = location
    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    const valuesFromUrl = toArray(filtersFromUrl[kind])
    let applyedFilterValues = replace ? value : [...valuesFromUrl, value]

    let filterValueToCheck = value
    let valuesToCheck = valuesFromUrl

    if (kind === 'nxStarsFilter') {
      const isNxStarsFilterInUrl = !!getKindFilterValue(location, 'nxStarsFilter')
      const productTypeValue = getKindFilterValue(location, 'collection')
      actions.setAvailableInStore(productTypeValue, !isNxStarsFilterInUrl)
    }

    if (kind === 'brand') {
      filterValueToCheck = getMainBrandFromSubBrands(value, brands) // value as 'OO, OJ'
      valuesToCheck = valuesFromUrl.map(br => getMainBrandFromSubBrands(br, brands))
    }

    if (valuesToCheck.includes(filterValueToCheck)) {
      const selectedFilters = valuesFromUrl.filter(
        v => (kind === 'brand' ? getMainBrandFromSubBrands(v, brands) : v) !== filterValueToCheck
      )
      applyedFilterValues = replace ? undefined : selectedFilters
    }

    return applyFilters(
      {
        ...filtersFromUrl,
        [kind]: applyedFilterValues,
      },
      history
    )
  }, [history, kind, replace, value, actions, brands])
}

export const useToggleOption = (filterOption: FilterOption, replace = false) => {
  const { name, value } = filterOption
  return useToggleUrlFilter(name, value, replace)
}

export const useClearFilter = () => {
  const history = useHistory()

  return useCallback(() => {
    const {
      location: { search },
    } = history

    const filtersFromUrl = qs.parse(search, { ignoreQueryPrefix: true }) as FiltersFromUrl
    const { q } = filtersFromUrl
    applyFilters({ q }, history)
  }, [history])
}

const updateFiltersWithCategory = (productFilters: FiltersState, filterFromUrl: FiltersFromUrl) => {
  const updatedFilters = productFilters
  updatedFilters.category.options = []
  Object.keys(productFilters).forEach(filterKind => {
    const categoryFilter = CATEGORIES[filterKind]
    if (!categoryFilter) return

    const productFilter = productFilters[filterKind]
    const categoryOptions = categoryFilter.options

    if (categoryOptions) {
      // option from other filterKind list (e.g. gender 3 - kids)
      categoryOptions.forEach(co => {
        const selectedOption = productFilter.options?.find(o => o.value === co.value)
        if (!selectedOption) return

        updatedFilters.category.options.push({
          ...selectedOption,
          ...co,
          selected: isInArrayOrEqual(filterFromUrl[co.name], co.value),
        })
        const updatedOptions = productFilter.options.filter(o => o.value !== co.value)
        updatedFilters[filterKind].options = updatedOptions
      })
    } else {
      // stand alone filterKind (e.g. polarized)
      const option = productFilter.options?.[0]
      if (!option) return

      updatedFilters.category.options.push({
        ...option,
        ...categoryFilter,
        selected: isInArrayOrEqual(filterFromUrl[categoryFilter.name], categoryFilter.value),
      })
    }
  })
  return updatedFilters
}

export const useFiltersState = (filtersFromProducts: FilterFacet[]) => {
  const filterFromUrl = useFiltersFromUrl()
  const store = useStoreContext()
  const brands = getConformedBrandsList(store.brands)

  const memoizedFilterState = useMemo(() => {
    const filterKindList = Object.keys(DEFAULT_FILTERS) as FilterKind[]
    const productFilterByKind = groupBy(filtersFromProducts, 'name')
    const filterWithOptions = {} as FiltersState

    filterKindList.forEach(filterKind => {
      const defaultFilter = (DEFAULT_FILTERS as DefaultFilters)[filterKind]

      const productFilters = productFilterByKind[filterKind]
      const urlFilter = filterFromUrl[filterKind]
      const newOptions = productFilters?.map(filter => ({
        ...filter,
        selected:
          filterKind === 'brand'
            ? isInBrandArray(urlFilter, filter.value, brands)
            : isInArrayOrEqual(urlFilter, filter.value),
      }))

      filterWithOptions[filterKind] = {
        ...defaultFilter,
        options: newOptions,
      }
    })

    return updateFiltersWithCategory(filterWithOptions, filterFromUrl)
  }, [filterFromUrl, filtersFromProducts, brands])

  return memoizedFilterState
}

export const useSelectedFiltersCount = (filters: FiltersState) => {
  const dispatch = useDispatch()
  const selectedFiltersCount = useMemo(
    () =>
      Object.values(filters).reduce((selectedCount, filter) => {
        const selectedOptionsCount = filter.options?.filter(o => o.selected).length || 0
        return selectedCount + selectedOptionsCount
      }, 0),
    [filters]
  )

  useEffect(() => {
    dispatch(setFiltersCount(selectedFiltersCount))
  }, [dispatch, selectedFiltersCount, filters])

  return selectedFiltersCount
}

export const useApplyFilters = (filters: ActiveFilters[]) => {
  const history = useHistory()

  if (!filters) return undefined

  const filterToApply: Record<string, string | string[]> = {}
  filters.forEach(f => {
    filterToApply[f.kind] = f.value
  })

  return () => {
    applyFilters(filterToApply, history)
  }
}

export const usePredefinedFilters = () => {
  const location = useLocation()

  const productTypeValue = getKindFilterValue(location, 'collection')
  const firstLevelFilter = getKindFilterValue(location, 'firstLevel')
  const secondLevelFilter = getKindFilterValue(location, 'secondLevel')
  const toggleProductTypeFilter = useToggleUrlFilter('collection', config.defaultProductType, true)

  const isLevelFilter = Boolean(firstLevelFilter || secondLevelFilter)
  if (!productTypeValue && !isLevelFilter) {
    toggleProductTypeFilter()
  }
}

const EXCLUDED_PRESELECTED_FILTERS = []

export const usePreselectedFilters = () => {
  const history = useHistory()
  const location = history.location
  // Preselected filters are preselected by the cms, from hero banner or menu usePredefinedFiltersption
  const [preselectedFilters, setPreselectedFilters] = useState<ActiveFilters[]>([])
  const hash = useRef<string>(uniqueId())
  const shouldUpdatePreselectedFilters = useSelector(s => s.ui.shouldUpdatePreselectedFilters)
  const actions = useActions({
    updatePreselectedFilters,
  })

  useEffect(() => {
    if (shouldUpdatePreselectedFilters) {
      hash.current = uniqueId()
    }
  }, [shouldUpdatePreselectedFilters])

  const activeFilters = getPLPFiltersKindAndValueFromLocation(location, []) as
    | ActiveFilters[]
    | undefined

  useEffect(() => {
    const filteredActiveFilters = activeFilters?.filter(
      f => !EXCLUDED_PRESELECTED_FILTERS.includes(f.kind)
    )
    if (filteredActiveFilters) {
      executeOnce((filteredActiveFilters: ActiveFilters[]) => {
        setPreselectedFilters(filteredActiveFilters)
        actions.updatePreselectedFilters(false)
      }, hash.current)(filteredActiveFilters)
    }
  }, [location, activeFilters, actions])

  return { preselectedFilters }
}

export const useFiltersContext = () => {
  const [selectedFilter, setSelectedFilter] = useState<ActiveFilter | null>(null)
  const selectedFilterId = selectedFilter?.id

  const onFilterClick = useCallback(
    (data: ActiveFilter) => {
      setSelectedFilter(data?.id === selectedFilterId ? null : data)
    },
    [selectedFilterId]
  )

  useEffect(() => {
    if (selectedFilter?.element) {
      selectedFilter.element.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [selectedFilter])

  const filtersContextValue = useMemo(
    () => ({
      activeFilterAccordion: selectedFilter,
      onClick: onFilterClick,
    }),
    [onFilterClick, selectedFilter]
  )

  return useMemo(
    () => ({
      onFilterClick,
      value: filtersContextValue,
    }),
    [filtersContextValue, onFilterClick]
  )
}

export const getFiltersFromUrl = (location: LocationHistory) => {
  const { search } = location
  if (search) {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
    if (parsedSearch) {
      return Object.keys(parsedSearch)
        .filter(name => ['q', 'sort'].indexOf(name) === -1)
        .map(kind => ({
          kind,
          value: parsedSearch[kind],
        }))
    }
  }
  return undefined
}

export const getWhitlistFilterKey = (value = '') => {
  switch (value.toLowerCase()) {
    case 'eyewear accessories':
      return 'eyewearAccessories'
    case 'replacement lenses':
      return 'replacementLenses'
    case 'frame components':
      return 'frameComponents'
    case 'other accessories':
      return 'otherAccessories'
    case 'goggles':
    case 'snow goggles':
    case 'mx goggles':
    case 'goggles accessories':
    case 'replacement lenses':
      return 'goggles'
    default:
      return 'eyeglasses'
  }
}
