import Maybe from 'graphql/tsutils/Maybe'
import { ProductTypeValues, SizeTypeValues } from './filter'
import {
  Brand,
  CmsAnalyticsData,
  ContentV2Image,
  ContentV2PlaylistItem,
  ContentV2Video,
  FrameAdvisorTag,
} from './graphqlTypes'
import { DataAttrs, Product } from './product'

export type PictureModeType = 'product' | 'dressedOn'

export interface FAFeatureSlide {
  title?: string
  icon?: React.ReactElement
  backgroundColor?: string
  isSmall?: boolean
}

export enum SkinUndertone {
  cool = 'COOL',
  warm = 'WARM',
}

export enum HairColor {
  bald = 'bald',
  black = 'black',
  blonde = 'blonde',
  brown = 'brown',
  grey = 'grey',
  other = 'other',
  othercolors = 'othercolors',
  red = 'red',
}

export enum EyeColor {
  blue = 'blue',
  green = 'green',
  brown = 'brown',
  other = 'other',
}

export type UndoData = {
  moco: string
  name: string
  model: string
  brand: Brand
  UPC: string
}

export type LoveHatesData = {
  status: string
  moco: string
  timestamp: string
  playListId?: string
  product: Product
}

export type BiometricData = {
  firstName: string
  lastName: string
  email: string
  isIllinoisResident: boolean
}

export enum FaceShape {
  OVAL_ROUND = 'oval_round',
  OVAL_ANGLED = 'oval_angled',
  ROUND = 'round',
  SQUARE = 'square',
  TRIANGLE = 'triangle',
}

export enum FaceLength {
  LONG = 'long',
  SHORT = 'short',
}

export enum Age {
  'adult' = 'adult',
  'child' = 'child',
  'senior_adult' = 'senior_adult',
  'young_adult' = 'young_adult',
}

export type FrameAdvisorVideoProfile = {
  uuid: string
  fov: number
  videoSource: string
  pd: {
    pd: number
    suggestedSize: string
  }
  age?: Maybe<Age>
  faceLength?: Maybe<FaceLength>
  faceShape?: Maybe<FaceShape>
  gender?: Maybe<'female' | 'male'>
  hairColor?: Maybe<HairColor>
  mood?: Maybe<'contrast' | 'harmony'>
  skinUndertones?: Maybe<'cool' | 'warm'>
  eyeColor?: Maybe<EyeColor>
}

export type AdvisorSuggestionKey =
  | 'eyeColor'
  | 'frameBridgePosition'
  | 'frameColor'
  | 'frameColorPalette'
  | 'frameDecoration'
  | 'frameRim'
  | 'frameShape'
  | 'frameShapeLift'
  | 'frameTempleFitting'
  | 'frameTempleThickness'
  | 'glassGender'
  | 'hairColorTone'
  | 'lensColorTreatment'
  | 'lensSize'
  | 'noseProminence'
  | 'skinUndertones'

export type AdvisorSuggestion = Record<AdvisorSuggestionKey, string[]>

export type FrameAdvisorUserProfile = {
  uuid: string
  profilePictureUrl: string
  pictureUuid: string
  videoUuid: string
  playlistId: string
  userInformation: {
    faceBreadth: number
    glassesType: string
  }
  userSearchInformation: {
    gender: string
    age: string
    skinUndertones: SkinUndertone
    faceShape: string
    faceLength: string
    hairColor: HairColor
    eyeColor: EyeColor
    eyebrowsThickness: string
    eyeMood: string
    sunMood: string
    darkCircles: string
  }
  advisorInformation: Record<FaProductType, Record<Mood, AdvisorSuggestion>>
}

export interface LoveHateThumbsProps {
  product: Product
  playlistId: string
  dataAttrs?: DataAttrs
  ThumbUpIcon?: React.FC
  ThumbDownIcon?: React.FC
  RemoveIcon?: React.FC
  ThumbUpLikedIcon?: React.FC
  onHateClick?: (product: Product, playlistId: string) => void
  onLoveClick?: (product: Product, playlistId: string) => void
  hateAction?: (product: Product, playlistId: string) => void
  loveAction?: (product: Product, playlistId: string) => void
  saveForUndo?: (product: Product) => void
  sendAddToWishlistEvent?: (product: Product) => void
  index?: number
  isItemLiked?: boolean
  handleRemove?: (product: Product) => void
  isBlackAndWhite?: boolean
  showDislikeButton?: boolean
  scrollToTop?: () => void
}

export enum FrameAdvisorTip {
  likeTip = 'likeTip',
  faceAndSkinTip = 'faceAndSkinTip',
  vmTip = 'vmTip',
  bestMatchTip = 'bestMatchTip',
  proTip = 'proTip',
  fitTip = 'fitTip',
  frameShapeTip = 'frameShapeTip',
  sizeTip = 'sizeTip',
  playlistTip = 'playlistTip',
}

export enum FaProductType {
  OPTICAL = 'EYEGLASSES',
  SUN = 'SUNGLASSES',
}

export enum Mood {
  HARMONY = 'HARMONY',
  CONTRAST = 'CONTRAST',
}

export type ColorPalettes = Record<string, string[]>

export interface SizeAdvisorData {
  numFound: number
  queryTime: number
  hingeDistance: number
  fitValue: string
  frameSizeFamily: string
  optimalHinge: number
  minHinge: number
  maxHinge: number
  advisorIntervals: AdvisorIntervals
}

export interface AdvisorIntervals {
  intervals: Interval[]
  lowerBound: number
  upperBound: number
  inRangeLowerBound: number
  inRangeUpperBound: number
}

export interface Interval {
  sizeName: SizeTypeValues
  enabledList: EnabledList[]
  bestFitInterval: boolean
  lowerBoundSize: number
  upperBoundSize: number
  inRangeLowerBoundSize: number
  inRangeUpperBoundSize: number
}

export interface EnabledList {
  inRange: boolean
  bestFit: boolean
  size: number
}

export interface FrameAdvisorOption {
  id: string
  title: string
  tags: FrameAdvisorTag[]
  nextStep: Maybe<string>
  analyticsData?: Maybe<CmsAnalyticsData>
  assetKey?: string
}
export interface FrameAdvisorStep {
  id: string
  slug: string
  title: string
  options: FrameAdvisorOption[]
  key?: string
}

export type FrameAdvisorSurvey = FrameAdvisorStep[]

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}
export interface CMSAnalyticsPlaylistTags {
  playlistTitleID: string
  playlistTitleDescription: string
}

export type ContentV2Playlist = {
  id: string
  title: string
  description: string
  image: ContentV2Image
  carousel: ContentV2Image[]
  elevationMoco: string[]
  exclusionMoco: string[]
  tags: string[]
  productType: string[]
  video: ContentV2Video
  font: string
  analyticsData: CMSAnalyticsPlaylistTags
}

export interface FrameAdvisorSurveyResults {
  age?: Age
  eye_color?: EyeColor
  dark_circles?: 'yes' | 'no'
  eyebrows_thickness?: string
  eye_mood?: Mood
  face_length?: FaceLength
  face_shape?: FaceShape
  gender?: Gender
  hair_color?: HairColor
  skin_undertones?: SkinUndertone
  glasses_type?: FaProductType
  sun_mood?: Mood
}

export interface UndertoneBanner {
  id: string
  title: string
  description: string
  banner: Maybe<ContentV2Image>
  key: string
}
export interface FrameAdvisorUndertones {
  banners: UndertoneBanner[]
}

export enum LikeStatus {
  LIKED = '0',
  DISLIKED = '1',
}

export type FilteredPlaylists = {
  [key in ProductTypeValues]: ContentV2PlaylistItem[]
}

export type FrameAdvisorViewMode = 'dressedOn' | 'product'
