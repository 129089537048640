import { usePreselectedFilters } from './useFilters'
import { getPLPFiltersKindAndValueFromLocation } from 'libs/url'
import { useHistory } from 'react-router-dom'
import { isEqual } from 'lodash'
import { checkUserMigrated } from 'libs/auth'

export const useAutoswitchCheck = (): boolean => {
  const history = useHistory()
  const { preselectedFilters } = usePreselectedFilters()
  const isUserMigrated = checkUserMigrated()

  if (isUserMigrated) {
    return true
  }

  const location = history.location
  const activeFilters = getPLPFiltersKindAndValueFromLocation(location)

  // do not allow autoswitch for filters selection
  return isEqual(preselectedFilters, activeFilters)
}

const hiddenTogglerFilters = [
  {
    polarized: 'true',
    collection: '2',
  },
]

export const useIsTogglerVisible = () => {
  const { preselectedFilters } = usePreselectedFilters()
  const isUserMigrated = checkUserMigrated()

  if (isUserMigrated) {
    return true
  }

  const preselectedFiltersObject = preselectedFilters.reduce((acc, filter) => {
    return {
      ...acc,
      [filter.kind]: filter.value,
    }
  }, {})
  const isFiltersInHidden = hiddenTogglerFilters.find(filtersList => {
    return Object.keys(filtersList).every(
      kind => preselectedFiltersObject[kind] === filtersList[kind]
    )
  })

  return !isFiltersInHidden
}
