import { Text } from 'components/Typography'
import { Device, landscape, tablet } from 'libs/media'
import { clampValues, clampValuesLandscape, pxToRem } from 'libs/styled'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { ICON_SIZE } from './ProductIcons/icons.styles'
import { ProductWishListActionButton } from './ProductWishListActionButton'

export const IMAGE_RATIO = '75%'
export const DRESSED_ON_IMAGE_RATIO = '133%'

export const CardProductContainerStyled = styled.div`
  min-height: 20vmin;
  color: ${({ theme }) => theme.productCard.color};
  border: ${({ theme }) => theme.productCard.border};
  display: flex;
  flex-direction: column;
  position: relative;
  isolation: isolate;
  padding: 0;
  height: auto;
`

export const IconsTop = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, ${ICON_SIZE});
  grid-gap: ${pxToRem(20)};
  position: absolute;
  padding: ${pxToRem(18)};

  & > * {
    z-index: 2;
  }
`

export const IconsBottomLeft = styled(IconsTop)`
  left: 0;
  bottom: 0;
  grid-template-columns: repeat(auto-fill, ${pxToRem(32)});
`

export const IconsStyled = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export const IconStyled = styled.img`
  margin-left: 0.2rem;
  height: 100%;
  width: auto;

  &:first-child {
    margin-left: 0;
  }
`

export const CardProductBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0.7rem 0;
  color: ${({ theme }) => theme.colors.primaryText};
  font-size: 0.8rem;
  p {
    margin-bottom: 0;
  }
`

export const CardBodyColumn = styled.div<{ isFullRow?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isFullRow }) => isFullRow && 'flex-basis: 100%;'}
`

export const AddBuyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 3;
  margin-top: ${pxToRem(10)};
  width: 100%;
`

const highlightDetail = `
  font-weight: bold;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`
export const ProductBrand = styled.p`
  order: ${({ theme }) => theme.productCard.brandNameOrder};
  color: ${({ theme }) => theme.colors.primaryText};
  ${highlightDetail};
`

export const ProductModel = styled.p<{ highlight: boolean }>`
  order: ${({ theme }) => theme.productCard.modelCodeOrder};
  color: ${({ theme }) => theme.colors.secondaryText};
  ${({ highlight }) => (highlight ? highlightDetail : '')}
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`

export const CardProductLinkStyled = styled(Link)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
`

export const ChipsContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: ${pxToRem(6)};
  padding: ${pxToRem(12)} ${pxToRem(17)};
  z-index: 1;
`

export const ChipsBottomLeft = styled(ChipsContainer)`
  bottom: 0;
  left: 0;
`

export const ChipsBottomRight = styled(ChipsContainer)`
  bottom: 0;
  right: 0;
`

export const ChipsTopLeft = styled(ChipsContainer)`
  flex-direction: row;
  top: 0;
  left: 0;
  padding: ${pxToRem(16)} ${pxToRem(16)};
`

export const Chip = styled.span`
  padding: 0.2em 0.9em;
  display: inline-block;
  height: 2em;
  border: 0.1em solid;
  border-radius: 50vmax;
  font-size: 0.4rem;
  text-transform: uppercase;
`

export const ColorTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 1.0909rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.primaryText};
  text-transform: capitalize;
`
export const RemoveButton = styled(Text)`
  padding: 0;
  border: 0;
  font-size: 1.136rem;
  font-weight: 400;
  text-decoration: underline;
  line-height: 1.499;
  height: 2rem;
  align-self: flex-end;
  z-index: 3;
  ${tablet(`
    font-size: 1.136rem;
    line-height: 1.499;
  `)}
  ${landscape(
    Device.tablet,
    `
    font-size: 1.136rem;
    line-height: 1.499;
  `
  )}
`

export const ProductWishListActionButtonStyled = styled(ProductWishListActionButton)<{
  displayWishlistIconAtBottom: boolean
}>`
  position: absolute;
  right: 0;
  margin: 1rem;
  z-index: 2;

  ${({ displayWishlistIconAtBottom }) =>
    displayWishlistIconAtBottom
      ? css`
          bottom: 0;
        `
      : css`
          top: 0;
        `};
`

export const ProductDescriptionStyled = styled.p`
  text-align: left;
  font-weight: 700;
`

export const DiscoverMoreLink = styled(Link)`
  text-align: left;
  position: absolute;
  bottom: ${pxToRem(16)};
  ${tablet(
    `
    bottom: ${clampValues(16, 16)};
  `
  )}
  ${landscape(
    Device.tablet,
    `
    bottom: ${clampValuesLandscape(16, 16)};
  `
  )}
`
