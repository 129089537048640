import { setBarcodeFlow } from 'actions/flows'
import { hideAllDrawer as hideAllDrawerAction } from 'actions/ui'
import ConditionalRender from 'components/ConditionalRender'
import Loading from 'components/Loading'
import { ProductList, ProductListProps } from 'components/ProductsList'
import config from 'config'
import { useSearchData } from 'data'
import { useAction } from 'hooks/useActions'
import { useNavigationFlowHandler } from 'hooks/useNavigationFlowHandler'
import { usePreviousNonNullish } from 'hooks/usePreviousNonNullish'
import { useStoreIndentity } from 'hooks/useStoreIdentity'
import { isEmpty } from 'lodash'
import { NavigationContext } from 'providers/navigationProvider'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { device } from 'styles/variables'
import { FilterInput } from 'types/filter'
import { PrimaryButton } from '../core/Button'
import { NotFoundMessage } from './NotFoundMessage'

const NUMBER_OF_PREVIEW_ITEMS = 2
const NUMBER_OF_PREVIEW_ITEMS_LANDSCAPE = 3

const NEW_ARRIVALS: FilterInput = {
  name: 'bestNew',
  value: 'new',
}

const BEST_SELLERS: FilterInput = {
  name: 'bestNew',
  value: 'bestSeller',
}

const Title = styled.p`
  width: 100%;
  font-size: 1.375rem;
  text-align: left;
  font-weight: 700;
`

const NoDataSection = styled.div`
  padding-left: 1rem;

  :not(:last-child) {
    margin-bottom: 7.625rem;
    @media ${device.tablet} {
      margin-bottom: 3.65rem;
    }
  }
`

const ContinueShoppingBtn = styled.div`
  text-align: center;
`

interface NoDataSuggestionListProps extends ProductListProps {
  title: string
}

export const NoDataSuggestionList: React.FC<NoDataSuggestionListProps> = ({ items, title }) => {
  return (
    <NoDataSection>
      <Title>{title}</Title>
      <ProductList items={items} isSearchList />
    </NoDataSection>
  )
}

interface NoDataContentProps {
  productTypeFilterValue?: string
  renderProductTypeTabs?: JSX.Element
}

export const NoDataContent: React.FC<NoDataContentProps> = ({
  productTypeFilterValue,
  renderProductTypeTabs,
}) => {
  const { t } = useTranslation()
  const { basePath } = useStoreIndentity()
  const history = useHistory()
  const hideAllDrawer = useAction(hideAllDrawerAction)
  const productPageUrl = `${basePath}/products`
  const { landscapeNavigation } = useContext(NavigationContext)
  const { barcodeFlow } = useNavigationFlowHandler()

  const collectionFilter: FilterInput = {
    name: 'collection',
    value: productTypeFilterValue || config.defaultProductType,
  }
  const filtersBestSeller: FilterInput[] = [collectionFilter, BEST_SELLERS]
  const filtersNewArrivals: FilterInput[] = [collectionFilter, NEW_ARRIVALS]

  const { data: bestSellerData, loading: bestSellerLoading } = useSearchData('', filtersBestSeller)
  const prevBestSellerData = usePreviousNonNullish(bestSellerData)
  const existingBestSellerData =
    !isEmpty(bestSellerData) && bestSellerData ? bestSellerData : prevBestSellerData

  const { data: newArrivalsData, loading: newArrivalsLoading } = useSearchData(
    '',
    filtersNewArrivals
  )
  const prevNewArrivalsData = usePreviousNonNullish(newArrivalsData)
  const existingNewArrivalsData =
    !isEmpty(newArrivalsData) && newArrivalsData ? newArrivalsData : prevNewArrivalsData

  if (newArrivalsLoading || bestSellerLoading) {
    return <Loading />
  }

  // Only bestseller items
  const filteredBestSellerItems = existingBestSellerData?.products.items.filter(item => !item.isNew)
  const bestSellerItems =
    filteredBestSellerItems.length &&
    filteredBestSellerItems.slice(
      0,
      landscapeNavigation ? NUMBER_OF_PREVIEW_ITEMS_LANDSCAPE : NUMBER_OF_PREVIEW_ITEMS
    )

  // Only new arrivals items
  const filteredNewArrivalsItems = existingNewArrivalsData?.products.items.filter(
    item => !item.isBestSellers
  )
  const newArrivalsItems =
    filteredNewArrivalsItems.length &&
    filteredNewArrivalsItems.slice(
      0,
      landscapeNavigation ? NUMBER_OF_PREVIEW_ITEMS_LANDSCAPE : NUMBER_OF_PREVIEW_ITEMS
    )

  return (
    <NoDataSection>
      <NotFoundMessage />
      <ConditionalRender condition={barcodeFlow}>{renderProductTypeTabs}</ConditionalRender>
      {bestSellerItems ? (
        <NoDataSuggestionList items={bestSellerItems} title={t('PlpFilters.bestSellers')} />
      ) : null}
      {newArrivalsItems ? (
        <NoDataSuggestionList items={newArrivalsItems} title={t('PlpFilters.newArrivals')} />
      ) : null}
      {!newArrivalsItems && !bestSellerItems && (
        <ContinueShoppingBtn>
          <PrimaryButton
            onClick={() => {
              history.push(productPageUrl)
              hideAllDrawer()
            }}
          >
            {t('Pages.viewAll')}
          </PrimaryButton>
        </ContinueShoppingBtn>
      )}
    </NoDataSection>
  )
}
