import React, { useEffect, useState } from 'react'
import { useApplyFilters, usePreselectedFilters } from 'hooks/useFilters'
import { ProductTypeToggler } from './ProductTypeToggler'
import { getOppositeProductType } from 'libs/utils'
import config from 'config'
import { useAutoswitchCheck, useIsTogglerVisible } from 'hooks/useToggleCheck'
import { ProductTypeValues } from 'types/filter'

interface ProductTypeTogglerWrapper {
  filterValue: string | undefined
  hasItems?: boolean
  scrollToTop?: () => void
  onChange?: (value: ProductTypeValues) => void
}

export const ProductTypeTogglerWrapper: React.FC<ProductTypeTogglerWrapper> = ({
  filterValue,
  hasItems,
  scrollToTop,
  onChange,
}) => {
  const [visible, setVisible] = useState(false)
  const { preselectedFilters } = usePreselectedFilters()
  const isAutoSwitchEnabled = useAutoswitchCheck()
  const isToggleVisible = useIsTogglerVisible()

  const oppositeProductType = getOppositeProductType(filterValue || config.defaultProductType)

  const autoToggleProduct = useApplyFilters([
    ...preselectedFilters,
    { kind: 'collection', value: oppositeProductType },
  ])

  const autoSwitchProductType = isAutoSwitchEnabled && !hasItems
  const isAllProductTypesAvailable = hasItems

  useEffect(() => {
    if (isAllProductTypesAvailable && isToggleVisible) {
      setVisible(true)
    }
    return () => {
      setVisible(false)
    }
  }, [isAllProductTypesAvailable, isToggleVisible])

  useEffect(() => {
    if (autoSwitchProductType) {
      autoToggleProduct && autoToggleProduct()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoSwitchProductType])

  return visible && !!filterValue ? (
    <ProductTypeToggler
      preselectedFilters={preselectedFilters}
      filterValue={filterValue}
      scrollToTop={scrollToTop}
      onChange={onChange}
    />
  ) : null
}
