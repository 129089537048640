import { removeItemFromFASelection } from 'actions/frameAdvisor'
import { ThumbUpIcon } from 'components/core/Icons/ThumbUp'
import { ThumbBtn } from 'components/core/ThumbBtn/styles'
import { useSendAnalyticsProductsEvent } from 'containers/AnalyticsProvider'
import { useActions } from 'hooks/useActions'
import { usePDPRouteParams } from 'hooks/usePDPRouteParams'
import { useWishList } from 'hooks/useWishlist'
import { clampValues } from 'libs/styled'
import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { DefaultTheme, ThemeContext } from 'styled-components'
import { DataAttrs, Product } from 'types/product'

export const ProductWishListActionButton: React.FC<{
  product: Product
  dataAttrs: DataAttrs
  isBlackAndWhite?: boolean
  className?: string
}> = ({ product, dataAttrs, isBlackAndWhite = true, className }) => {
  const { wishlisted, toggleItemToWishlist } = useWishList(product.UPC)
  const history = useHistory()
  const theme: DefaultTheme = React.useContext(ThemeContext)
  const { comesFromFrameAdvisor } = usePDPRouteParams(history)
  const sendAddToWishlistEvent = useSendAnalyticsProductsEvent({
    id: 'ProdFavAdd',
  })

  const actions = useActions({
    removeItemFromFASelection,
  })

  const handleToggleWishlist = React.useCallback(
    (product: Product) => {
      toggleItemToWishlist(product)
      if (wishlisted) {
        actions.removeItemFromFASelection(product)
      }
      if (!wishlisted) {
        sendAddToWishlistEvent && sendAddToWishlistEvent(product)
      }
    },
    [toggleItemToWishlist, wishlisted, actions, sendAddToWishlistEvent]
  )

  const backgroundColor = !comesFromFrameAdvisor
    ? theme.colors.primaryText
    : isBlackAndWhite
    ? theme.colors.black
    : theme.colors.green

  return (
    <ThumbBtn
      className={className}
      onClick={() => handleToggleWishlist(product)}
      data-test="button-addToWishlist"
      data-element-id={dataAttrs && dataAttrs.wishlist}
      data-description={dataAttrs && dataAttrs.description}
      isActive={wishlisted}
      backgroundColor={backgroundColor}
    >
      <ThumbUpIcon
        stroke={wishlisted ? theme.cta.textColor : theme.colors.black}
        fill={wishlisted ? theme.cta.textColor : theme.colors.black}
        height={clampValues(14, 20)}
        width={clampValues(14, 20)}
      />
    </ThumbBtn>
  )
}
